import { useMemo } from 'react';
import { routes } from 'src/utils/routes';

export function useProjectData({ project }) {
  const mainImages = useMemo(
    () =>
      project?.photos?.main_image?.map((photo) => {
        return {
          original: photo,
          thumbnail: photo,
        };
      }) || [],
    [project]
  );

  return {
    projectDetailURL: routes.projectDetail.path.replace(':projectUuid', project?.uuid),
    websiteURL: 'https://' + project?.web?.replace('https://', ''),
    mainImages,
  };
}
