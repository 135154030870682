import { useEffect, useState } from 'react';
// import { BREAKPOINTS } from '../utils/constant';

export const useBreakpoint = () => {
  const [breakpoint] = useState('');
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  const handleResize = () => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();

    // if (0 < windowSize.width && windowSize.width < 600) {
    //   setBreakPoint(BREAKPOINTS[0]);
    // }
    // if (600 < windowSize.width && windowSize.width < 900) {
    //   setBreakPoint(BREAKPOINTS[600]);
    // }
    // if (960 < windowSize.width && windowSize.width < 1200) {
    //   setBreakPoint(BREAKPOINTS[900]);
    // }
    // if (1280 < windowSize.width && windowSize.width < 1536) {
    //   setBreakPoint(BREAKPOINTS[1200]);
    // }
    // if (windowSize.width >= 1536) {
    //   setBreakPoint(BREAKPOINTS[1536]);
    // }

    return () => window.removeEventListener('resize', handleResize);
  }, [windowSize.width]);
  return { breakpoint, windowSize };
};
