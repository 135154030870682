import { getCurrentState } from '../store';
import { LOCALIZATION, MESSAGES, TEXT } from './constant';
import { v4 as uuidv4 } from 'uuid';

export const projectNameValidator = (name) => {
  if (name && name.length < 5) {
    return 'Название должно содержать более 4 символов';
  }
  return false;
};

export const getWordEvent = (amount) => {
  // 1 событие, 2 события, 5 событий
  const transText = getTransText();
  const str = String(amount);
  const lastChar = str.slice(-1);
  if (amount === 1) {
    return transText.EVENT;
  }
  if (amount === 11 || amount === 0) {
    return transText.EVENTS;
  }
  if (lastChar === '1') {
    return transText.EVENTS;
  } else if (['2', '3', '4'].includes(lastChar)) {
    return transText.EVENTS;
  }
  return transText.EVENTSS;
};

export const urlValidator = (value) => {
  const error = 'Хост не валиден';
  if (!value || typeof value !== 'string') {
    return error;
  }

  const validHostnameChars = /^[a-zA-Z0-9-.]{1,253}\.?$/g;
  if (!validHostnameChars.test(value)) {
    return error;
  }

  if (value.endsWith('.')) {
    value = value.slice(0, value.length - 1);
  }

  if (value.length > 253) {
    return error;
  }

  const labels = value.split('.');

  const isValid = labels.every(function (label) {
    const validLabelChars = /^([a-zA-Z0-9-]+)$/g;

    const validLabel =
      validLabelChars.test(label) && label.length < 64 && !label.startsWith('-') && !label.endsWith('-');

    return validLabel;
  });

  return isValid ? false : error;
};

export const checkIsAdmin = (user) => {
  if (!user) return false;
  return user.role === 'admin';
};

export const toCustomShort = (num) => {
  return new Intl.NumberFormat('en-US', {
    notation: 'compact',
    compactDisplay: 'short',
  }).format(num);
};

export const getFullName = (user = {}) => {
  const { firstName = '', middleName = '', lastName = '' } = user;
  let fullName = '';
  if (lastName) fullName += lastName;
  if (firstName) fullName += ` ${firstName}`;
  if (middleName) fullName += ` ${middleName}`;
  return fullName;
};

export const getPartsOfFullName = (fullName = '') => {
  const arrName = fullName.trim().split(/\s+/) ?? [];
  const lastName = arrName[0];
  const firstName = arrName[1];
  const middleName = arrName.slice(2).join(' ');
  return { lastName, firstName, middleName };
};

export const uniqueArrayBase = (arr) => arr.filter((value, index, self) => self.indexOf(value) === index);

export const uniqueArray = (arr, compare = () => true) => {
  return arr.filter(compare);
};

export const getTransText = () => {
  const lang = getCurrentState().app.lang || LOCALIZATION.ru_RU;
  return TEXT[lang];
};

export const getTransMessages = () => {
  const lang = getCurrentState().app.lang || LOCALIZATION.ru_RU;
  return MESSAGES[lang];
};

export const getLocalizationValue = (object, lang = LOCALIZATION.ru_RU, field) =>
  object?.localization?.[lang]?.[field] || '';

export const isSafari =
  /constructor/i.test(window.HTMLElement) ||
  (function (p) {
    return p.toString() === '[object SafariRemoteNotification]';
  })(!window['safari'] || (typeof safari !== 'undefined' && window['safari'].pushNotification));

export const generateUuid = () => uuidv4();

export function generateRandomPassword(length = 8) {
  const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let password = '';

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * chars.length);
    password += chars[randomIndex];
  }

  return password;
}
