// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { cloneDeep, uniqueId } from 'lodash';
import { userAPIs, userChatAPIs } from 'src/services';
import { generateUuid, getFullName } from 'src/utils/common';
import queryString from 'query-string';

const LIMIT_ROWS = 10;

const formatChatMessages = (messages) => {
  return messages.map((message) => ({
    ...message,
    senderId: message.userUuid,
    time: message.createdAt,
    message: message.body,
    feedback: {
      isSent: true,
      isDelivered: false,
      isSeen: false,
    },
  }));
};

// ** Fetch User Vizit
export const fetchUserVizit = createAsyncThunk('appChat/fetchUserVizit', async (projectUuid) => {
  const [responseVizit, responseMemberVizits] = await Promise.all([
    userAPIs.getUserVizitsProject(projectUuid),
    userAPIs.getMembersVizitsProject(projectUuid),
  ]);
  return { userVizit: responseVizit.message, userMemberVizits: responseMemberVizits.message };
});

// ** Fetch User Profile
export const fetchUserProfile = createAsyncThunk('appChat/fetchUserProfile', async (_, { getState }) => {
  const currentUser = cloneDeep(getState().auth.user);
  return {
    ...currentUser,
    id: currentUser.uuid,
    fullName: getFullName(currentUser),
  };
});

// ** Fetch Chats & Contacts
export const fetchChatsContacts = createAsyncThunk('appChat/fetchChatsContacts', async (_, { getState }) => {
  const currentState = cloneDeep(getState());
  const currentUser = currentState.auth.user;
  const lang = currentState.app.lang;
  const userMemberVizits = currentState.chat.userMemberVizits;

  const response = await userChatAPIs.getChats(
    queryString.stringify({ memberUuid: userMemberVizits?.[0]?.uuid, limit: LIMIT_ROWS, offset: 0 })
  );
  const chats = response.message;

  let contacts = [];
  const chatsContacts = chats.map((chat) => {
    const participants = chat.participants;
    let targetMemberUuid;
    Object.keys(participants).forEach((uuid) =>
      uuid !== currentUser.uuid
        ? (targetMemberUuid = uuid)
        : contacts.push({ ...participants[uuid], id: uuid, userUuid: uuid })
    );

    return {
      ...chat,
      id: chat.uuid,
      avatarColor: 'primary',
      fullName: participants[targetMemberUuid]?.fullName,
      chat: {
        lastMessage: {
          message: chat.lastMessage,
          time: chat.updatedAt,
          senderId: currentUser.uuid,
          feedback: {
            isSent: true,
            isDelivered: false,
            isSeen: false,
          },
        },
      },
    };
  });
  return { chatsContacts, contacts, canLoadMoreChats: chats.length === LIMIT_ROWS };
});

// ** Select Chat
export const selectChat = createAsyncThunk('appChat/selectChat', async (id, { dispatch, getState }) => {
  const currentState = cloneDeep(getState());
  const currentUser = currentState.auth.user;
  const chats = currentState.chat.chats;
  if (id) {
    dispatch(loadingChat(true));
  }

  const response = await userChatAPIs.getChatDetail(id, queryString.stringify({ limit: LIMIT_ROWS, offset: 0 }));
  const chatMessages = response.message;
  const currentChat = chats.find((c) => c.uuid === id);
  const participants = currentChat.participants;
  const contactUuid = Object.keys(participants).find((uuid) => uuid !== currentUser.uuid);

  await dispatch(fetchChatsContacts());
  return {
    chat: {
      ...chats.find((c) => c.uuid === id),
      chat: formatChatMessages(chatMessages),
      canLoadMore: chatMessages.length === LIMIT_ROWS,
    },
    contact: { ...participants[contactUuid], userUuid: contactUuid },
  };
});

// ** Send Msg
export const sendMsg = createAsyncThunk('appChat/sendMsg', async (obj, { dispatch, getState }) => {
  if (obj.chat) {
    const currentState = cloneDeep(getState());
    const currentUser = currentState.auth.user;
    const currentErrorMessages = currentState.chat.errorMessages || [];
    const messageUuid = generateUuid();
    const chatUuid = obj.chat.uuid;
    const message = obj.message;
    const currentTime = new Date().toISOString();

    if (obj.isResend) {
      dispatch(removeMessage(obj.messageUuid));
    }

    dispatch(
      addNewMessage(
        formatChatMessages([
          {
            body: message,
            uuid: messageUuid,
            createdAt: currentTime,
            userUuid: currentUser.uuid,
          },
        ])
      )
    );
    try {
      await userChatAPIs.sendMessage(chatUuid, { body: message });

      dispatch(
        updateChatContact({
          chatUuid: chatUuid,
          lastMessage: {
            message: message,
            time: currentTime,
            senderId: currentUser.uuid,
            feedback: {
              isSent: true,
              isDelivered: false,
              isSeen: false,
            },
          },
        })
      );
    } catch (error) {
      console.error(error);
      dispatch(setErrorMessages([...currentErrorMessages, messageUuid]));
    }
  }
});

// ** Fetch more chat messages
export const fetchMoreChatMessages = createAsyncThunk(
  'appChat/fetchMoreChatMessages',
  async (obj, { dispatch, getState }) => {
    const currentState = cloneDeep(getState());
    const selectedChat = currentState.chat.selectedChat;
    if ((!selectedChat?.chat || !selectedChat?.chat?.canLoadMore) && !obj.hasNewMessage) return;

    const response = await userChatAPIs.getChatDetail(
      selectedChat?.chat?.id,
      queryString.stringify({ limit: LIMIT_ROWS, offset: selectedChat.chat.chat.length })
    );
    const updatedChat = {
      ...selectedChat,
      chat: {
        ...(selectedChat.chat || {}),
        chat: selectedChat.chat.chat.concat(formatChatMessages(response.message)),
        canLoadMore: response.message.length === LIMIT_ROWS,
      },
    };
    return updatedChat;
  }
);

// ** Fetch more chats
export const fetchMoreChats = createAsyncThunk('appChat/fetchMoreChats', async (_, { dispatch, getState }) => {
  const currentState = cloneDeep(getState());
  const store = currentState.chat;
  const chats = currentState.chat.chats;
  const currentUser = currentState.auth.user;
  if (!store.canLoadMore) return;

  const userMemberVizits = currentState.chat.userMemberVizits;

  const response = await userChatAPIs.getChats(
    queryString.stringify({ memberUuid: userMemberVizits?.[0]?.uuid, limit: LIMIT_ROWS, offset: chats.length })
  );
  const updatedChats = chats.concat(
    response.message.map((chat) => {
      const participants = chat.participants;
      let targetMemberUuid;
      Object.keys(participants).forEach((uuid) => {
        if (uuid !== currentUser.uuid) targetMemberUuid = uuid;
      });

      return {
        ...chat,
        id: chat.uuid,
        avatarColor: 'primary',
        fullName: participants[targetMemberUuid]?.fullName,
        chat: {
          lastMessage: {
            message: chat.lastMessage,
            time: chat.updatedAt,
            senderId: currentUser.uuid,
            feedback: {
              isSent: true,
              isDelivered: false,
              isSeen: false,
            },
          },
        },
      };
    })
  );

  return updatedChats;
});

export const fetchMeetingsInChat = createAsyncThunk(
  'appChat/fetchMeetingsInChat',
  async ({ chatUuid, projectUuid }, { dispatch, getState }) => {
    dispatch(loadingMeetings(true));
    const response = await userAPIs.getUserMeetings(projectUuid, '', chatUuid);
    return response.message;
  }
);

const initialState = {
  isLoadingChat: false,
  isLoadingMeetings: false,

  canLoadMoreChats: false,
  chats: null,
  contacts: null,
  userProfile: null,
  selectedChat: null,
  userVizit: null,
  userMemberVizits: [],
  meetingsChat: [],
  errorMessages: [],
};

export const appChatSlice = createSlice({
  name: 'appChat',
  initialState,
  reducers: {
    removeSelectedChat: (state) => {
      state.selectedChat = null;
    },
    loadingChat: (state, action) => {
      const isLoading = action.payload;
      if (isLoading) {
        state.meetingsChat = [];
      }
      state.isLoadingChat = isLoading;
      state.errorMessages = [];
    },
    loadingMeetings: (state, action) => {
      const isLoading = action.payload;
      state.isLoadingMeetings = isLoading;
    },
    addNewMessage: (state, action) => {
      state.selectedChat.chat.chat = state.selectedChat.chat.chat?.concat(action.payload);
    },
    removeMessage: (state, action) => {
      state.selectedChat.chat.chat = state.selectedChat.chat.chat?.filter((m) => m.uuid !== action.payload);
    },
    setErrorMessages: (state, action) => {
      state.errorMessages = action.payload;
    },
    updateChatContact: (state, action) => {
      state.chats = state.chats.map((chat) => {
        if (chat.uuid === action.payload.chatUuid) {
          return { ...chat, chat: { lastMessage: action.payload.lastMessage } };
        }
        return chat;
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUserVizit.fulfilled, (state, action) => {
      state.userVizit = action.payload.userVizit;
      state.userMemberVizits = action.payload.userMemberVizits;
    });
    builder.addCase(fetchUserProfile.fulfilled, (state, action) => {
      state.userProfile = action.payload;
    });
    builder.addCase(fetchChatsContacts.fulfilled, (state, action) => {
      state.contacts = action.payload.contacts;
      state.chats = action.payload.chatsContacts;
      state.canLoadMoreChats = action.payload.canLoadMoreChats;
      state.isLoadingChat = false;
    });
    builder.addCase(selectChat.fulfilled, (state, action) => {
      state.selectedChat = action.payload;
      state.isLoadingChat = false;
    });
    builder.addCase(fetchMoreChatMessages.fulfilled, (state, action) => {
      state.selectedChat = action.payload;
    });
    builder.addCase(fetchMoreChats.fulfilled, (state, action) => {
      state.chats = action.payload;
    });
    builder.addCase(fetchMeetingsInChat.fulfilled, (state, action) => {
      state.meetingsChat = action.payload;
      state.isLoadingMeetings = false;
    });
  },
});

export const {
  removeSelectedChat,
  loadingChat,
  addNewMessage,
  setErrorMessages,
  updateChatContact,
  removeMessage,
  loadingMeetings,
} = appChatSlice.actions;

export default appChatSlice.reducer;
