import React, { useEffect, useState } from 'react';
import { userAPIs } from '../../services';
import { Typography, Button, Box, Stack } from '@mui/material';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import qs from 'query-string';
import Loader from '../../components/loader';
import { createNotification } from '../../utils/notifications';

function VerifyUserPage() {
  const { userUuid } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const [isCodeValid, setIsCodeValid] = useState(false);
  const [isVerifying, setIsVerifying] = useState(true);

  const { code: verifyCode } = qs.parse(location.search);

  const handleVerifyCode = async () => {
    try {
      await userAPIs.verifyCode(userUuid, verifyCode);
      setIsCodeValid(true);
      createNotification('Verify successfully', 'success');
      // setTimeout(() => {
      //   navigate('/');
      // }, 300);
    } catch (error) {
      setIsCodeValid(false);
      console.error(error);
    } finally {
      setIsVerifying(false);
    }
  };

  useEffect(() => {
    handleVerifyCode();
  }, []);

  return (
    <Box sx={{ maxWidth: 400, margin: 'auto', textAlign: 'center', paddingTop: '20vh' }}>
      {isVerifying ? (
        <Stack gap="16px">
          <Loader />
          <Typography variant="h6">Проверка кода...</Typography>
        </Stack>
      ) : (
        <>
          {!isCodeValid && verifyCode && (
            <Stack gap="24px" alignItems="center">
              <img src="/images/error.png" width="64px" height="64px" />
              <Typography variant="h6" color="error">
                Ссылка неверна или просрочена
              </Typography>
            </Stack>
          )}
        </>
      )}
      {isCodeValid && (
        <Stack gap="24px" alignItems="center">
          <img src="/images/success.png" width="64px" height="64px" />
          <Typography variant="h6" color="green">
            Ваша электронная почта подтверждена!
          </Typography>
        </Stack>
      )}
    </Box>
  );
}

export default VerifyUserPage;
