import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import moment from 'moment';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import ButtonLoading from 'src/components/button-loading';
import DatePicker from 'src/components/date-picker';
import { useLanguage } from 'src/hooks';
import { userScheduleAPIs } from 'src/services';
import { ScheduleValidationSchema } from 'src/utils/formValidator';
import { createErrorNotification, createNotification } from 'src/utils/notifications';
import Autocomplete from '@mui/material/Autocomplete';

function TeamScheduleForm({ projectUuid, type, selectedRow, onClose, users, currentUser }) {
  const { lang, localizeText, localizeMessage } = useLanguage();
  const [selectedUser, setSelectedUser] = useState(null);

  const {
    control,
    formState: { errors, isSubmitting },
    getValues,
    setValue,
    register,
    handleSubmit,
  } = useForm({
    defaultValues: {
      name: selectedRow?.name || '',
      date: moment(selectedRow?.date).toDate() ?? '',
      location: selectedRow?.location ?? '',
      participantUuid: selectedRow?.participantUuid,
      peopleCount: 1,
      schedule_number: selectedRow?.schedule_number ?? '',
    },
    resolver: yupResolver(ScheduleValidationSchema(lang)),
  });

  const locations = [
    'Аэропорт Пулково',
    'Ж/Д вокзал Московский',
    'Ж/Д вокзал Финляндский',
    'Ж/Д вокзал Ладожский',
    'Ж/Д вокзал Балтийский',
    'Ж/Д вокзал Витебский',
    'Автовокзал (м. Обводный канал)',
  ];

  const formatUTC = (dateInt, addOffset = false) => {
    let date = !dateInt || dateInt.length < 1 ? new Date() : new Date(dateInt);
    if (typeof dateInt === 'string') {
      return date;
    } else {
      const offset = addOffset ? date.getTimezoneOffset() : -date.getTimezoneOffset();
      const offsetDate = new Date();
      offsetDate.setTime(date.getTime() + offset * 60000);
      return offsetDate;
    }
  };

  const handleSubmitForm = async (values) => {
    try {
      const date = new Date(values.date);
      if (selectedRow) {
        await userScheduleAPIs.editSchedules(selectedRow.uuid, {
          ...values,
          date,
        });
      } else {
        await userScheduleAPIs.createSchedules({
          ...values,
          type,
          projectUuid,
          date,
        });
      }

      createNotification(localizeMessage.SAVE_SUCCESSFULLY);
    } catch (error) {
      console.error(error);
      createErrorNotification(error?.message?.error || localizeMessage.ERROR);
    } finally {
      onClose();
    }
  };
  return (
    <Stack
      sx={{ margin: '2rem auto', width: { xs: '100%', md: '50%' } }}
      alignItems="center"
      component="form"
      onSubmit={handleSubmit(handleSubmitForm)}
    >
      <Stack gap="24px" sx={{ width: '100%' }}>
        <Typography variant="h5" textAlign="center" textTransform="capitalize">
          {selectedRow ? localizeText.EDIT_SCHEDULE : localizeText.ADD_SCHEDULE}
        </Typography>

        <FormControl margin="normal" error={errors.countryOfRegistration ? true : false}>
          <InputLabel
            required
            shrink={true}
            variant="standard"
            sx={{
              '& .MuiInputLabel-asterisk': {
                color: 'red',
              },
              fontSize: 17,
            }}
          >
            {localizeText.SELECT_USER}
          </InputLabel>
          <Select
            variant="standard"
            margin="dense"
            defaultValue={getValues('participantUuid')}
            {...register('participantUuid')}
            onChange={(e) => {
              const uuid = e.target.value;
              setValue('participantUuid', uuid);
              const user = users.find((el) => el.uuid === uuid);
              setValue('name', (user.lastName || '') + ' ' + (user.firstName || '') + ' ' + (user.middleName || ''));
              setSelectedUser(e.target.value);
            }}
          >
            {users.map((user) => (
              <MenuItem disabled={!user.projectSettings[projectUuid]?.isAccredited} key={user.uuid} value={user.uuid}>
                {(user.lastName || '') + ' ' + (user.firstName || '') + ' ' + (user.middleName || '')}{' '}
                {currentUser?.uuid === user.uuid ? '(Это вы)' : ''}
              </MenuItem>
            ))}
          </Select>
          {errors.countryOfRegistration && (
            <FormHelperText sx={{ marginLeft: 0, marginRight: 0 }} error={true}>
              {errors.countryOfRegistration.message}
            </FormHelperText>
          )}
        </FormControl>
        <Controller
          hidden={!selectedUser}
          name="date"
          control={control}
          render={({ field }) => (
            <DatePicker
              locale={lang?.split('_')?.[0] || 'ru'}
              required={true}
              selected={formatUTC(field.value, true) || ''}
              onChange={(date) => {
                field.onChange(formatUTC(date));
              }}
              showTimeInput
              timeFormat="HH:mm"
              dateFormat="dd/MM/yyyy HH:mm"
              showYearDropdown
              showMonthDropdown
              timeInputLabel={localizeText.TIME}
              customInput={
                <TextField
                  InputLabelProps={{ shrink: true }}
                  variant="standard"
                  label={localizeText.DATE}
                  fullWidth={true}
                  error={!!errors.date}
                  helperText={errors.date?.message}
                />
              }
            />
          )}
        />
        <Controller
          name="schedule_number"
          control={control}
          render={({ field }) => (
            <Autocomplete
              freeSolo
              autoSelect
              {...field}
              options={['Введите номер рейса']}
              onChange={(event, newInputValue) => {
                field.onChange(newInputValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  hidden={!selectedUser}
                  required
                  variant="standard"
                  label={localizeText.ARRIVAL_RACE}
                  InputLabelProps={{ shrink: true }}
                  error={!!errors.schedule_number}
                  helperText={errors.schedule_number?.message}
                />
              )}
            />
          )}
        />

        <Controller
          name="location"
          control={control}
          render={({ field }) => (
            <Autocomplete
              freeSolo
              autoSelect
              {...field}
              options={locations}
              onChange={(event, newInputValue) => {
                field.onChange(newInputValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  hidden={!selectedUser}
                  required
                  variant="standard"
                  label={localizeText.LOCATION}
                  InputLabelProps={{ shrink: true }}
                  error={!!errors.location}
                  helperText={errors.location?.message}
                />
              )}
            />
          )}
        />

        <Stack direction="row" gap="24px">
          <Button variant="outlined" color="primary" size="large" sx={{ width: '50%' }} onClick={onClose}>
            {localizeText.CANCEL}
          </Button>
          <ButtonLoading
            variant="contained"
            type="submit"
            size="large"
            sx={{ width: '50%' }}
            isLoading={isSubmitting}
            disabled={isSubmitting || !getValues().name}
          >
            {localizeText.OK}
          </ButtonLoading>
        </Stack>
      </Stack>
    </Stack>
  );
}

export default TeamScheduleForm;
