import { Close } from '@mui/icons-material';
import { Box, Button, Grid, IconButton, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { dispatch } from 'src/store';
import { setNotifications } from 'src/store/actions/app';
import { fetchMoreChatMessages } from 'src/store/apps/chat';
import { EVENTS, MESSAGES, TEXT } from 'src/utils/constant';
import eventBus from 'src/utils/eventBus';
import { routes } from 'src/utils/routes';

function useNotification() {
  const navigate = useNavigate();
  let socket = null;
  const notifications = useSelector((state) => state.app.notifications || []);
  const lang = useSelector((state) => state.app.lang);
  const selectedChat = useSelector((state) => state.chat.selectedChat);

  const NOTIFICATION_TYPES = {
    text: { key: 'text', message: MESSAGES[lang].YOU_HAVE_A_NEW_MESSAGE },
    meeting: { key: 'meeting', message: MESSAGES[lang].YOU_HAVE_A_NEW_MEETING_BOOKING },
  };

  const NOTIFICATION_TYPE_KEYS = Object.values(NOTIFICATION_TYPES).map((value) => value.key);
  const BASE_SOCKET_URL = `${process.env.REACT_APP_SERVER_URL?.replace(/(https:|http:)/, 'wss:')}/api/websocketer`;
  const SOCKET_URL = `${BASE_SOCKET_URL}?token=${localStorage.getItem('accessToken')}`;

  const handleRefetchChat = (chatUuid) => {
    if (chatUuid === selectedChat?.chat?.uuid) {
      dispatch(fetchMoreChatMessages({ hasNewMessage: true }));
    }
  };

  useEffect(() => {
    eventBus.on('CHECK_SELECTED_CHAT', handleRefetchChat);
    return () => {
      eventBus.off('CHECK_SELECTED_CHAT', handleRefetchChat);
    };
  }, [selectedChat]);

  useEffect(() => {
    function registerWebsocket(url, callback) {
      socket = new WebSocket(url);
      socket.onopen = function (e) {
        console.log('Connected to socket !!!');
        if (callback) {
          callback();
        }
      };
      socket.onmessage = (event) => {
        const parsedMessage = JSON.parse(event.data);
        const messageData = parsedMessage.data || {};
        const messageType = parsedMessage.type;
        const { chatUuid, projectUuid, meetingUuid, message } = messageData;

        if (messageType === NOTIFICATION_TYPES.text.key && chatUuid) {
          eventBus.emit('CHECK_SELECTED_CHAT', chatUuid);
        }
        if (NOTIFICATION_TYPE_KEYS.includes(messageType)) {
          eventBus.emit(EVENTS.PLAY_NOTIFICATION_SOUND);
          toast(
            ({ closeToast, toastProps }) => (
              <Grid container alignItems="center">
                <Grid item xs={9}>
                  <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                    {NOTIFICATION_TYPES[messageType]?.message}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                  >
                    {message}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Button
                    size="small"
                    onClick={() => {
                      if (messageType === NOTIFICATION_TYPES.meeting.key && !chatUuid) {
                        navigate(
                          routes.projectVizits.path.replace(':projectUuid', projectUuid) +
                            (meetingUuid ? `?tab=meetings&meetingUuid=${meetingUuid}` : '')
                        );
                        return;
                      }
                      navigate(
                        routes.projectChat.path.replace(':projectUuid', projectUuid) +
                          (chatUuid ? `?chatUuid=${chatUuid}` : '')
                      );
                    }}
                  >
                    {TEXT[lang].DETAIL}
                  </Button>
                </Grid>
                <button
                  className="Toastify__close-button Toastify__close-button--light"
                  style={{ position: 'absolute', top: 8, right: 8 }}
                  onClick={closeToast}
                >
                  <Close />
                </button>
              </Grid>
            ),
            {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: false,
              closeButton: false,
            }
          );
        }
      };
      socket.onclose = function (e) {
        setTimeout(function () {
          console.log('Reconnecting to socket !!!');
          registerWebsocket(SOCKET_URL, () => {
            setNotifications([]);
          });
        }, 1000);
      };
    }

    if (!socket) {
      registerWebsocket(SOCKET_URL, () => {
        setNotifications([]);
      });
    }
    return () => {
      socket.close(1000);
    };
  }, []);

  return { notifications };
}

export default useNotification;
