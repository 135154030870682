import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import { Button, IconButton, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import MeetingActions from 'src/components/meeting-actions';
import MeetingTime from 'src/components/meeting-time';
import MeetingStatus from 'src/components/status/MeetingStatus';
import { TEXT } from 'src/utils/constant';

function ChatMeetings({ meetings, onUpdateMeetingStatus }) {
  const currentUser = useSelector((state) => state.auth.user);
  const lang = useSelector((state) => state.app.lang);
  const { showTableNumber } = useSelector((state) => state.app.projectSettings || {});
  const [showIndex, setShowIndex] = useState(0);

  const meeting = meetings[showIndex];
  if (!meeting) return null;
  return (
    <Stack
      direction="row"
      justifyContent="space-around"
      gap="16px"
      flexWrap="wrap"
      //   sx={{ '&:hover': { background: '#eeeeee' }, padding: '4px' }}
    >
      <Stack direction="row" alignItems="center" gap="8px">
        <MeetingTime startTime={meeting.startTime} endTime={meeting.endTime} />
        {showTableNumber && (
          <>
            {' - '}
            <Typography variant="body1">{TEXT[lang].TABLE_NO + ' ' + meeting.tableNumber}</Typography>
          </>
        )}
      </Stack>
      {meeting?.status && <MeetingStatus status={meeting.status} />}
      {meeting.ownerUuid !== currentUser.uuid && (
        <MeetingActions meeting={meeting} onUpdateMeetingStatus={onUpdateMeetingStatus} />
      )}
      <Stack direction="row" gap="4px">
        <Button
          variant="outlined"
          color="secondary"
          sx={{ height: '24px', width: '24px', minWidth: '24px', borderRadius: '4px', padding: '0' }}
          disabled={showIndex === meetings.length - 1}
          onClick={() => setShowIndex(showIndex + 1)}
        >
          <ArrowDropUp />
        </Button>
        <Button
          variant="outlined"
          color="secondary"
          sx={{ height: '24px', width: '24px', minWidth: '24px', borderRadius: '4px', padding: '0' }}
          disabled={showIndex === 0}
          onClick={() => setShowIndex(showIndex - 1)}
        >
          <ArrowDropDown />
        </Button>
      </Stack>
    </Stack>
  );
}

export default ChatMeetings;
