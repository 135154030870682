import { Typography } from '@mui/material';
import moment from 'moment-timezone';
import React from 'react';
import { useSelector } from 'react-redux';
import { TEXT } from 'src/utils/constant';
import { DEFAULT_TIMEZONE } from 'src/utils/time';

function MeetingTime({ startTime, endTime }) {
  const lang = useSelector((state) => state.app.lang);

  if (!startTime || !endTime) return null;
  return (
    <div>
      <Typography variant="body1" component="span" fontWeight="bold">
        {`${moment.tz(startTime, DEFAULT_TIMEZONE).format(`DD MMMM `)}`}
      </Typography>
      <Typography variant="body1" component="span">
        {`${moment.tz(startTime, DEFAULT_TIMEZONE).format(`[${TEXT[lang].AT}] HH:mm`)} - ${moment
          .tz(endTime, DEFAULT_TIMEZONE)
          .format('HH:mm')}`}
      </Typography>
    </div>
  );
}

export default MeetingTime;
