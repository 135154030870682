import queryString from 'query-string';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { routes } from 'src/utils/routes';
import { removePackagesInOrder, setPayingOrders } from '../../store/actions/app';
import classes from './payment-success.module.sass';

const PaymentSuccess = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const query = queryString.parse(location.search);
  const [isInit, setIsInit] = useState(false);
  const payingOrders = useSelector((state) => state.app.payingOrders);

  const removeOrder = () => {
    const newPayingOrders = [];
    payingOrders.forEach(async (order) => {
      if (order.uuid === query.order) {
        await removePackagesInOrder(order.projectUuid, order.cart);
      } else {
        newPayingOrders.push(order);
      }
    });
    setPayingOrders(newPayingOrders);
  };

  useEffect(() => {
    if (query?.order && !isInit) {
      removeOrder();
      setIsInit(true);
      setTimeout(() => {
        navigate(routes.myOrder.path.replace(':projectUuid', query.projectUuid));
      }, 500);
    }
  }, [query, isInit]);

  return <p className={classes.payment}>Оплата успешно</p>;
};

export default PaymentSuccess;
