import React, { useEffect } from 'react';
import { generalAPIs } from '../../services';
import { LOCALIZATION, MESSAGES } from '../../utils/constant';
import { createErrorNotification, createNotification } from '../../utils/notifications';
import qs from 'query-string';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

function VCardPage() {
  const code = qs.parse(useLocation().search)?.code;
  const lang = useSelector((state) => state.app.lang) || LOCALIZATION.ru_RU;

  useEffect(() => {
    const downloadVCard = async () => {
      try {
        await generalAPIs.downloadVCard(code);
        createNotification(MESSAGES[lang].DOWNLOAD_SUCCESSFULLY);
      } catch (error) {
        console.error(error);
        createErrorNotification(MESSAGES[lang].ERROR);
      } finally {
        setTimeout(() => {
          window.close();
        }, 1000);
      }
    };

    downloadVCard();
  }, []);
  return <></>;
}

export default VCardPage;
