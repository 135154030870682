import { request } from 'src/utils/request';
import queryString from 'query-string';

export const documentAPIs = {
  getDocuments: (queryParams) =>
    request(`/internal/documents${queryParams ? '?' + queryString.stringify(queryParams) : ''}`),
  uploadDocument: (body) => request(`/internal/documents`, { method: 'PUT', body }),
  getDocumentFile: (docUuid) => request(`/internal/documents/${docUuid}`, { method: 'GET' }, false, true),
  deleteDocumentFile: (docUuid) => request(`/internal/documents/${docUuid}`, { method: 'DELETE' }),
};
