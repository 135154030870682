import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { getProjectsList, setCurrentProject } from 'src/store/actions/app';

export function useCurrentProject({ projectUuid }) {
  const queryResult = useQuery(
    ['fetchProject', projectUuid],
    async ({ queryKey }) => {
      const [, projectUuid] = queryKey;
      const projects = await getProjectsList(`uuid=${projectUuid}`, false);
      const project = projects[0];
      setCurrentProject(project);
      return project;
    },
    { enabled: !!projectUuid }
  );

  return { projectUuid, ...queryResult };
}
