import { Avatar, Chip, Skeleton, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { routes } from 'src/utils/routes';
import { getFullName } from '../../utils/common';
import { LOCALIZATION, TEXT } from '../../utils/constant';
import classes from './profile-summary.module.sass';

export function ProfileSummary({ inLeftMenu, navigateToProfile, showStatus = true }) {
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.user);
  const lang = useSelector((state) => state.app.lang) || LOCALIZATION.ru_RU;
  const isLoadingAvatar = useSelector((state) => state.auth.isLoadingAvatar);

  if (!user) return null;
  return (
    <>
      <div
        className={classes.root}
        style={{ cursor: navigateToProfile ? 'pointer' : 'auto' }}
        onClick={() => {
          if (navigateToProfile) {
            navigate(routes.profile.path);
          }
        }}
      >
        {isLoadingAvatar ? (
          <Skeleton variant="circular" className={`${classes.avatar} ${inLeftMenu ? classes.avatarBig : ''}`} />
        ) : (
          <Avatar
            alt="avatar"
            className={`${classes.avatar} ${inLeftMenu ? classes.avatarBig : ''}`}
            component="div"
            sizes="large"
            src={user?.avatar}
          >
            {(user.lastName?.[0] || '') + (user.firstName?.[0] || '')}
          </Avatar>
        )}
        <div className={classes.info}>
          <Typography
            sx={{
              fontSize: inLeftMenu ? '1.2rem' : '1rem',
              fontWeight: 600,
              overflowWrap: 'anywhere',
              color: inLeftMenu ? 'white' : 'text.primary',
              textTransform: 'capitalize',
            }}
          >
            {getFullName(user)}
          </Typography>
          <Typography
            variant="body2"
            sx={{
              fontSize: inLeftMenu ? '1rem' : '0.8rem',
              color: inLeftMenu ? 'white' : 'text.disabled',
              overflowWrap: 'anywhere',
            }}
          >
            {user?.email}
          </Typography>
          {showStatus && user.status === 1 && <Chip label={TEXT[lang].VERIFIED} color="success" sx={{ mt: '2px' }} />}
        </div>
      </div>
    </>
  );
}
