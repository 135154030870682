import { Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import CustomRadioIcons from 'src/@core/components/custom-radio/icons';
import { getLocalizationValue } from 'src/utils/common';
import StepFooter from './StepFooter';

function StepOne({
  projects,
  selectedProject,
  setSelectedProject,
  activeStep,
  steps,
  handleNext = () => {},
  handlePrev = () => {},
}) {
  const lang = useSelector((state) => state.app.lang);

  const handleRadioChange = (prop) => {
    if (typeof prop === 'string') {
      setSelectedProject(prop);
    } else {
      setSelectedProject(prop.target.value);
    }
    handleNext();
  };

  return (
    <>
      <Grid container spacing={6} justifyContent="center" marginY="20px">
        {projects.map((project, index) => (
          <CustomRadioIcons
            key={index}
            data={{ title: getLocalizationValue(project, lang, 'fullName') || project.name, value: project.uuid }}
            logo={project?.photos?.logo?.[0]}
            selected={selectedProject}
            gridProps={{ xs: 12, md: 4, sx: { textAlign: 'center' } }}
            handleChange={handleRadioChange}
          />
        ))}
      </Grid>
      <StepFooter
        activeStep={activeStep}
        steps={steps}
        checkAbleNext={() => !!selectedProject}
        handlePrev={handlePrev}
        handleNext={handleNext}
      />
    </>
  );
}

export default StepOne;
