import { Add, Delete, ModeEdit } from '@mui/icons-material';
import { Button, IconButton, Stack, Typography } from '@mui/material';
import moment from 'moment';
import queryString from 'query-string';
import { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';
import Loader from 'src/components/loader';
import { MUITable } from 'src/components/mui-table';
import { useLanguage } from 'src/hooks';
import { userScheduleAPIs } from 'src/services';
import { EVENTS, SCHEDULE_TYPES } from 'src/utils/constant';
import eventBus from 'src/utils/eventBus';
import { createErrorNotification, createNotification } from 'src/utils/notifications';
import TeamScheduleForm from './TeamScheduleForm';

function TeamScheduleTable({ projectUuid, type = SCHEDULE_TYPES.ARRIVAL, users, currentUser }) {
  const location = useLocation();
  const queryParams = queryString.parse(location.search);
  const { page: queryPage, limit } = queryParams || {};
  const rowsPerPage = parseInt(limit) || 20;
  const page = Math.max(queryPage || 1, 1);

  const [openForm, setOpenForm] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const { localizeText, localizeMessage } = useLanguage();

  const { isLoading, isFetching, data, refetch } = useQuery(
    ['fetchSchedules', projectUuid, type, rowsPerPage, page],
    async ({ queryKey }) => {
      const [, projectUuid, type, rowsPerPage, page] = queryKey;
      const response = await userScheduleAPIs.getSchedules(projectUuid, type, rowsPerPage, rowsPerPage * (page - 1));
      return response.message;
    },
    { enabled: !!projectUuid, staleTime: 0 }
  );

  const handleDeleteRow = (row) => {
    eventBus.emit(EVENTS.OPEN_CONFIRM_MODAL, {
      onOk: async () => {
        try {
          await userScheduleAPIs.deleteSchedules(row.uuid);
          createNotification(localizeMessage.DELETE_SUCCESSFULLY);
          refetch();
        } catch (error) {
          console.error(error);
          createErrorNotification(localizeMessage.ERROR);
        }
      },
    });
  };

  const headCells = [
    {
      id: 'stt',
      numeric: true,
      disablePadding: true,
      label: 'STT',
      renderRow: (row, rowIndex) => <Typography>{rowIndex + 1}</Typography>,
    },
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: localizeText.FULLNAME,
      renderRow: (row) => <Typography>{row.name}</Typography>,
    },
    {
      id: 'date',
      numeric: false,
      disablePadding: false,
      label: type === SCHEDULE_TYPES.ARRIVAL ? localizeText.ARRIVAL_DATE : localizeText.DEPARTURE_DATE,
      renderRow: (row) => <Typography>{moment(row.date).utc().format('DD/MM/YYYY')}</Typography>,
    },
    {
      id: 'time',
      numeric: false,
      disablePadding: false,
      label: type === SCHEDULE_TYPES.ARRIVAL ? localizeText.ARRIVAL_TIME : localizeText.DEPARTURE_TIME,
      renderRow: (row) => <Typography>{moment(row.date).utc().format('HH:mm')}</Typography>,
    },
    {
      id: 'schedule_number',
      numeric: false,
      disablePadding: false,
      label: type === SCHEDULE_TYPES.ARRIVAL ? localizeText.ARRIVAL_RACE : localizeText.DEPARTURE_RACE,
      renderRow: (row) => <Typography>{row.schedule_number}</Typography>,
    },
    {
      id: 'location',
      numeric: false,
      disablePadding: false,
      label: type === SCHEDULE_TYPES.ARRIVAL ? localizeText.MEETING_PLACE : localizeText.DEPARTURE_LOCATION,
      renderRow: (row) => <Typography>{row.location}</Typography>,
    },
    {
      id: 'action',
      numeric: false,
      disablePadding: false,
      label: '',
      renderRow: (row) => (
        <Stack direction="row" gap="8px">
          <IconButton
            color="primary"
            size="small"
            sx={{ borderRadius: '4px', border: '1px solid' }}
            onClick={() => {
              setSelectedRow(row);
              setOpenForm(true);
            }}
          >
            <ModeEdit />
          </IconButton>
          <IconButton
            color="error"
            size="small"
            sx={{ borderRadius: '4px', border: '1px solid' }}
            onClick={() => handleDeleteRow(row)}
          >
            <Delete />
          </IconButton>
        </Stack>
      ),
    },
  ];

  const rows = useMemo(() => {
    return (data?.schedules || []).sort((a, b) =>
      a.participantUuid === currentUser.uuid && b.participantUuid !== currentUser.uuid
        ? -1
        : a.participantUuid !== currentUser.uuid && b.participantUuid === currentUser.uuid
        ? 1
        : 0
    );
  }, [data]);
  return (
    <Stack gap="1rem" position="relative">
      {openForm ? (
        <TeamScheduleForm
          currentUser={currentUser}
          users={users}
          projectUuid={projectUuid}
          type={type}
          selectedRow={selectedRow}
          onClose={() => {
            setOpenForm(false);
            setSelectedRow(null);
            refetch();
          }}
        />
      ) : (
        <>
          <Stack direction="row">
            <Button variant="contained" startIcon={<Add />} onClick={() => setOpenForm(true)}>
              {localizeText.ADD_SCHEDULE}
            </Button>
          </Stack>
          {isLoading ? (
            <Loader />
          ) : (
            <MUITable
              id="user-schedule-table"
              loading={isLoading || isFetching}
              headCells={headCells}
              rows={rows}
              rowsNum={data?.totalCount ?? 0}
              // onClickRow={handleClickRow}
            />
          )}
        </>
      )}
    </Stack>
  );
}

export default TeamScheduleTable;
