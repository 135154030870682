import { EVENTS } from 'src/utils/constant';
import eventBus from 'src/utils/eventBus';
import useLanguage from '../useLanguage';

export function useProjectManagement({ projectUuid, user }) {
  const { localizeText } = useLanguage();

  function getIsJoinedProject(user, projectUuid) {
    return user?.projectUuids?.includes(projectUuid);
  }

  function confirmJoinProject({ onSuccess = async (projectUuid) => {} }) {
    eventBus.emit(EVENTS.OPEN_CONFIRM_MODAL, {
      onOk: async () => {
        await onSuccess(projectUuid);
      },
      title: localizeText.CONFIRM,
      content: localizeText.PARTICIPATE_PROJECT,
      okText: localizeText.OK,
      cancelText: localizeText.CANCEL,
    });
  }

  return {
    isJoinedProject: getIsJoinedProject(user, projectUuid),
    confirmJoinProject,
  };
}
