import React, { useEffect, useState } from 'react';
import { Button as MuiButton, CircularProgress } from '@mui/material';

function ButtonLoading({ isLoading, disabled, onClick = async () => {}, children, ...restProps }) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isMounted, setIsMounted] = useState(false);

  const handleClick = async () => {
    if (isSubmitting) return;
    try {
      setIsSubmitting(true);
      await onClick();
    } catch (error) {
      console.error(error);
    } finally {
      if (isMounted) setIsSubmitting(false);
    }
  };

  useEffect(() => {
    setIsMounted(true);
    return () => {
      setIsMounted(false);
    };
  }, []);

  return (
    <MuiButton
      startIcon={isLoading || isSubmitting ? <CircularProgress size="12px" color="inherit" /> : <></>}
      disabled={disabled || isSubmitting}
      onClick={handleClick}
      {...restProps}
    >
      {children}
    </MuiButton>
  );
}

export default ButtonLoading;
