import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider, createTheme } from '@mui/material/styles';

import themeOptions from './ThemeOptions';

const ThemeComponent = (props) => {
  const { children } = props;

  let theme = createTheme(themeOptions());

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};

export default ThemeComponent;
