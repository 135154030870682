import { configureStore } from '@reduxjs/toolkit';
import { createLogger } from 'redux-logger';

import createRootReducer from './reducers';

const rootReducer = createRootReducer();

const middleware = [];

const preloadedState = localStorage.getItem('expoState') ? JSON.parse(localStorage.getItem('expoState')) : {};

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => {
    let middleware = getDefaultMiddleware({
      serializableCheck: false,
    });

    // if (process.env.NODE_ENV == 'development') {
    //   middleware = middleware.concat(createLogger({ collapsed: true }));
    // }
    return middleware;
  },
  preloadedState,
});

store.subscribe(() => {
  localStorage.setItem('expoState', JSON.stringify(store.getState()));
});

export const getCurrentState = () => store.getState();
export const dispatch = (...args) => store.dispatch(...args);

export default store;
