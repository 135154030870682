import { useQuery } from 'react-query';
import { userAPIs } from 'src/services';

function useMeetings({ projectUuid, status }) {
  return useQuery(
    ['fetchMeetings', projectUuid, status],
    async ({ queryKey }) => {
      const [, projectUuid, status] = queryKey;
      const response = await userAPIs.getUserMeetings(projectUuid, status);
      const rawMeetings = response.message || [];
      return rawMeetings;
    },
    { enabled: !!projectUuid }
  );
}

export default useMeetings;
