import { Stack, Typography } from '@mui/material';
import React from 'react';
import { getFullName } from 'src/utils/common';

function MemberProfile({ member, descriptionElement = <></> }) {
  return (
    <Stack>
      <Typography color="inherit" variant="h6" fontWeight={600}>
        {member.email}
      </Typography>
      <Typography color="inherit" sx={{ textTransform: 'capitalize' }}>
        {getFullName(member)}
      </Typography>
      {descriptionElement}
    </Stack>
  );
}

export default MemberProfile;
