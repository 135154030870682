import { Stack, Typography } from '@mui/material';
import moment from 'moment';
import { useRef } from 'react';
import LazyLoad from 'react-lazyload';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useProjectImage from 'src/hooks/useProjectImage';
import { LOCALIZATION } from '../../utils/constant';
import JoinProjectButton from '../join-project-button';
import classes from './project.module.sass';

export const Project = ({ project }) => {
  const navigate = useNavigate();
  const unixTimeDelta = 1000;
  const lang = useSelector((state) => state.app.lang) || LOCALIZATION.ru_RU;
  const projectRef = useRef();
  // const { mainPhoto } = useProjectImage({ projectRef, project });
  const mainPhoto = project.photos?.web_big?.[0];

  const handleGoToProject = () => {
    navigate(`/projects/${project.uuid}`);
  };

  const startDate = new Date(project.dateStartPlan * unixTimeDelta);
  const finishDate = new Date(project.dateFinishPlan * unixTimeDelta);
  const startMonthName = moment(startDate).format('MMMM');
  const finishMonthName = moment(finishDate).format('MMMM');

  return (
    <Stack className={classes.root} ref={projectRef} onClick={handleGoToProject}>
      {mainPhoto && (
        <LazyLoad>
          <img className={classes.logo} src={mainPhoto} alt="img" />
        </LazyLoad>
      )}
      <Stack className={mainPhoto ? classes.joinProjectButton : classes.joinProjectButtonInline}>
        <JoinProjectButton project={project} size="small" />
      </Stack>
      <div>
        <Typography className={classes.name}>{project.localization?.[lang]?.shortName}</Typography>
        <div className={classes.dateRange}>
          {startDate.getDate()} {startMonthName} - {finishDate.getDate()} {finishMonthName} {startDate.getFullYear()}
        </div>
        <div>
          <Typography className={classes.description}>{project.localization?.[lang]?.comment}</Typography>
        </div>
      </div>
    </Stack>
  );
};
