import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';
import qs from 'query-string';
import { generalAPIs } from '../../services';
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Divider,
  Grid,
  InputAdornment,
  Stack,
  styled,
  TextareaAutosize,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { LogoIcon } from '../../icons';
import Loader from '../../components/loader';
import { LOCALIZATION, MESSAGES, TEXT } from '../../utils/constant';
import { useSelector } from 'react-redux';
import Members from '../vizits/components/members';
import { LanguageMuiSelect } from '../../components/language-select';
import classes from './public-vizit.module.sass';
import CompanyForm from '../vizits/components/company-form';
import { Email, LocationCity, Person, Phone, LocationOn, Public, Link, Download } from '@mui/icons-material';
import { createErrorNotification, createNotification } from '../../utils/notifications';
import { QRCodeSVG } from 'qrcode.react';

const TextFieldStyled = styled(TextField)({
  '& .Mui-disabled': {
    fill: '#000 !important',
    '-webkit-text-fill-color': '#000 !important',
  },
});

function PublicVizitPage() {
  const code = qs.parse(useLocation().search)?.code;
  // const lang = useSelector((state) => state.app.lang) || LOCALIZATION.ru_RU;
  const lang = LOCALIZATION.ru_RU;
  const countries = useSelector((state) => state.app.countries);

  const fetchPublicVizit = async ({ queryKey }) => {
    const [_, code] = queryKey;
    try {
      const token = localStorage.getItem('accessToken');
      if (token) {
        const response = await generalAPIs.getPublicVizitWithAuth(code);
        return response.message;
      } else {
        const response = await generalAPIs.getPublicVizit(code);
        return response.message;
      }
    } catch (error) {
      if (error.code === 401) {
        const response = await generalAPIs.getPublicVizit(code);
        return response.message;
      }
    }
  };

  const { isLoading, data: vizit } = useQuery(['fetchPublicVizit', code], fetchPublicVizit, { enabled: !!code });

  const membersVizit = vizit?.members || [];
  const projectUuid = vizit?.projectUuid;

  const getCountryName = (country) => {
    if (!country) return '';
    return `${country.localization?.[lang]?.name} ${country.shortNames?.[0] && ` (${country.shortNames?.[0]})`}`;
  };

  const handleDownloadVCard = async () => {
    try {
      await generalAPIs.downloadVCard(code);
      createNotification(MESSAGES[lang].DOWNLOAD_SUCCESSFULLY);
    } catch (error) {
      console.error(error);
      createErrorNotification(MESSAGES[lang].ERROR);
    }
  };

  const [vCardLink, setVCardLink] = useState('');

  useEffect(() => {
    if (code) {
      setVCardLink(`${window.location.origin}/v-card?code=${code}`);
    }
  }, [code]);

  return (
    <Container sx={{ paddingY: '40px' }}>
      <Stack gap="24px">
        <Stack direction={{ xs: 'column', md: 'row' }} alignItems="center" justifyContent="space-between" gap="24px">
          <Stack direction="row">
            <LogoIcon color="#c5c6cb" />
          </Stack>
          <Stack direction="row" gap="16px" alignItems="center" sx={{ display: { xs: 'none', md: 'flex' } }}>
            <Button
              variant="outlined"
              startIcon={<Download />}
              onClick={handleDownloadVCard}
              sx={{ height: 'fit-content', textTransform: 'none' }}
            >
              {MESSAGES[lang].DOWNLOAD_VCARD}
            </Button>
            {vCardLink && <QRCodeSVG value={vCardLink} height={80} width={80} />}
            {/* <LanguageMuiSelect /> */}
          </Stack>
        </Stack>

        <Card
          sx={{
            boxShadow: '0px 2px 10px 0px rgba(19, 17, 32, 0.15)',
            borderRadius: '30px',
            padding: { xs: '1rem', md: '2rem' },
          }}
        >
          <CardContent sx={{ padding: 0 }}>
            {isLoading ? (
              <Loader />
            ) : vizit ? (
              <Stack gap="24px" sx={{ h2: { margin: '0px 0px 12px' } }}>
                <Typography
                  variant="h1"
                  sx={{
                    display: { xs: 'none', md: 'block' },
                    textAlign: 'center',
                    fontSize: { xs: '32px', md: '48px' },
                    marginBottom: '24px',
                  }}
                >
                  {vizit.name}
                </Typography>
                <Grid container spacing={6}>
                  <Grid item xs={12} md={6}>
                    <Box>
                      <Grid container spacing={4}>
                        {vizit.logo && (
                          <Grid item xs={4} md={5}>
                            <Stack className={classes.imageWrapper}>
                              <label htmlFor="logo-file">
                                <img
                                  src={vizit.logo + `?dummy=${new Date().getTime()}`}
                                  className={classes.image}
                                  alt="logo"
                                  width="100%"
                                  style={{ maxWidth: '250px' }}
                                />
                              </label>
                            </Stack>
                          </Grid>
                        )}
                        <Grid
                          item
                          xs={8}
                          sx={{
                            display: { xs: 'flex', md: 'none' },
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                          }}
                        >
                          <Stack direction="row" gap="8px" alignItems="center">
                            <Button
                              variant="outlined"
                              startIcon={<Download />}
                              onClick={handleDownloadVCard}
                              sx={{ height: 'fit-content', textTransform: 'none' }}
                            >
                              {MESSAGES[lang].DOWNLOAD_VCARD}
                            </Button>
                            {vCardLink && <QRCodeSVG value={vCardLink} height={80} width={80} />}
                          </Stack>
                        </Grid>

                        <Grid item xs={12} md={vizit.logo ? 7 : 12} sx={{ margin: 'auto' }}>
                          <Stack gap="16px">
                            <Tooltip title={TEXT[lang].COMPANY_NAME}>
                              <TextFieldStyled
                                value={vizit.name}
                                size="small"
                                variant="standard"
                                fullWidth
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <Person />
                                    </InputAdornment>
                                  ),
                                }}
                                disabled
                              />
                            </Tooltip>
                            <Tooltip title={TEXT[lang].EMAIL_COMPANY}>
                              <TextFieldStyled
                                value={vizit.email}
                                size="small"
                                variant="standard"
                                fullWidth
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <Email />
                                    </InputAdornment>
                                  ),
                                }}
                                disabled
                              />
                            </Tooltip>
                            <Tooltip title={TEXT[lang].TELEPHONE_COMPANY}>
                              <TextFieldStyled
                                value={vizit.telephone}
                                size="small"
                                variant="standard"
                                fullWidth
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <Phone />
                                    </InputAdornment>
                                  ),
                                }}
                                disabled
                              />
                            </Tooltip>
                            <Tooltip title={TEXT[lang].COUNTRY_COMPANY}>
                              <TextFieldStyled
                                value={getCountryName(countries.find((country) => country.code === vizit.country))}
                                size="small"
                                variant="standard"
                                fullWidth
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <Public />
                                    </InputAdornment>
                                  ),
                                }}
                                disabled
                              />
                            </Tooltip>
                            <Tooltip title={TEXT[lang].REGION}>
                              <TextFieldStyled
                                value={vizit.region}
                                size="small"
                                variant="standard"
                                fullWidth
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <LocationCity />
                                    </InputAdornment>
                                  ),
                                }}
                                disabled
                              />
                            </Tooltip>
                            <Tooltip title={TEXT[lang].ADDRESS_COMPANY}>
                              <TextFieldStyled
                                value={vizit.address}
                                size="small"
                                variant="standard"
                                fullWidth
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <LocationOn />
                                    </InputAdornment>
                                  ),
                                }}
                                disabled
                              />
                            </Tooltip>
                            <Tooltip title={TEXT[lang].WEB_ADDRESS}>
                              <TextFieldStyled
                                value={vizit.web}
                                size="small"
                                variant="standard"
                                fullWidth
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <Link />
                                    </InputAdornment>
                                  ),
                                }}
                                disabled
                              />
                            </Tooltip>
                          </Stack>
                        </Grid>
                        <Grid item xs={12}>
                          <Stack>
                            <TextFieldStyled
                              label={TEXT[lang].SCOPE_OF_COMPANY}
                              margin="normal"
                              variant="standard"
                              value={vizit?.scope}
                              disabled
                            />
                            <TextFieldStyled
                              label={TEXT[lang].OFFERED_PRODUCT_OR_SERVICES}
                              margin="normal"
                              variant="standard"
                              value={vizit?.services}
                              disabled
                            />
                            <TextFieldStyled
                              multiline={true}
                              value={vizit?.description}
                              minRows={6}
                              margin="normal"
                              disabled
                            />
                          </Stack>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Box>
                      <Grid container spacing={4}>
                        {membersVizit.map((member, i) => (
                          <Members
                            key={i}
                            member={member}
                            projectUuid={projectUuid}
                            membersVizits={membersVizit}
                            //   setMembersVizits={setMembersVizits}
                            readOnly={true}
                            fullRow={true}
                          />
                        ))}
                      </Grid>
                    </Box>
                  </Grid>
                </Grid>
              </Stack>
            ) : (
              <Typography variant="h6">{MESSAGES[lang].VIZIT_NOT_FOUND}</Typography>
            )}
          </CardContent>
        </Card>
      </Stack>
    </Container>
  );
}

export default PublicVizitPage;
