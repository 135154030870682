export const AddShoppingIcon = ({ color = '#FFF' }) => {
  return (
    <svg width="25" height="20" viewBox="0 0 25 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.999998 4.21713e-05C0.734782 4.21713e-05 0.480429 0.105399 0.292893 0.292935C0.105357 0.480471 0 0.734824 0 1.00004C0 1.26526 0.105357 1.51961 0.292893 1.70715C0.480429 1.89468 0.734782 2.00004 0.999998 2.00004H3.21999L5.84399 12.5C6.06699 13.39 6.86399 14 7.78098 14H20.251C21.154 14 21.921 13.4 22.158 12.53L24.75 3.00004H22.656L20.25 12H7.77998L5.15699 1.50004C5.04864 1.06927 4.79887 0.687319 4.44767 0.415355C4.09647 0.143391 3.66417 -0.00285293 3.21999 4.21713e-05H0.999998ZM19 14C17.355 14 16 15.355 16 17C16 18.645 17.355 20 19 20C20.645 20 22 18.645 22 17C22 15.355 20.645 14 19 14ZM9.99998 14C8.35498 14 6.99999 15.355 6.99999 17C6.99999 18.645 8.35498 20 9.99998 20C11.645 20 13 18.645 13 17C13 15.355 11.645 14 9.99998 14ZM13 4.21713e-05V5.00003H9.99998L14 9.00002L18 5.00003H15V4.21713e-05H13ZM9.99998 16C10.564 16 11 16.436 11 17C11 17.564 10.564 18 9.99998 18C9.43598 18 8.99998 17.564 8.99998 17C8.99998 16.436 9.43598 16 9.99998 16ZM19 16C19.564 16 20 16.436 20 17C20 17.564 19.564 18 19 18C18.436 18 18 17.564 18 17C18 16.436 18.436 16 19 16Z"
        fill={color}
      />
    </svg>
  );
};
