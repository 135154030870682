import CloseIcon from '@mui/icons-material/Close';
import { Avatar, CircularProgress, Dialog, DialogContent, Divider, IconButton, Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getServicesOfPackages } from '../../store/actions/app';
import { EVENTS, LOCALIZATION, TEXT } from '../../utils/constant';
import eventBus from '../../utils/eventBus';
import classes from './members-modal.module.sass';

export const MembersModal = () => {
  const lang = useSelector((state) => state.app.lang) || LOCALIZATION.ru_RU;
  const [state, setState] = useState({
    show: false,
    isLoading: false,
    members: [],
    onOk: () => {},
  });

  const handleCancel = () => setState({ show: false });
  const handleShow = async (data) => {
    setState({ ...state, ...data, show: true });
  };

  useEffect(() => {
    eventBus.on(EVENTS.OPEN_MEMBERS_MODAL, handleShow);
    return () => {
      eventBus.off(EVENTS.OPEN_MEMBERS_MODAL, handleShow);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Dialog
        sx={{
          '& .MuiDialog-paper': {
            minWidth: '50vw',
            minHeight: '60%',
            borderRadius: '30px',
            border: '4px solid #c1c1ff',
          },
        }}
        maxWidth="md"
        open={state.show}
        onClose={handleCancel}
      >
        <DialogContent>
          <IconButton
            aria-label="close"
            onClick={handleCancel}
            sx={{
              position: 'absolute',
              right: 16,
              top: 16,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          {state.isLoading ? (
            <div className={classes.loading}>
              <CircularProgress />
            </div>
          ) : (
            <div className={classes.body}>
              <Stack gap="8px">
                {state?.members?.map((member, iMember) => (
                  <React.Fragment key={iMember}>
                    <Stack direction="row" gap="8px">
                      {member?.avatar && <Avatar className={classes.avatar} src={member?.avatar} />}
                      <Stack gap="8px">
                        <Stack direction="row" flexWrap="wrap" columnGap="8px">
                          <div style={{ fontWeight: 'bold' }}>{TEXT[lang].NAME_COMPANY}:</div>
                          <div style={{ hyphens: 'auto' }}>{member?.localization?.[lang]?.name}</div>
                        </Stack>
                        {member?.localization?.[lang]?.type && (
                          <Stack direction="row" flexWrap="wrap" columnGap="8px">
                            <div style={{ fontWeight: 'bold' }}>{TEXT[lang].TYPE}:</div>
                            <div style={{ hyphens: 'auto' }}>{member?.localization?.[lang]?.type}</div>
                          </Stack>
                        )}
                        {member?.localization?.[lang]?.position && (
                          <Stack direction="row" flexWrap="wrap" columnGap="8px">
                            <div style={{ fontWeight: 'bold' }}>{TEXT[lang].POSITION}:</div>
                            <div style={{ hyphens: 'auto' }}>{member?.localization?.[lang]?.position}</div>
                          </Stack>
                        )}
                      </Stack>
                    </Stack>
                    {iMember < state?.members?.length - 1 && <Divider />}
                  </React.Fragment>
                ))}
              </Stack>
            </div>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};
