import queryString from 'query-string';
import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export function useLocationManagement() {
  const navigate = useNavigate();
  const location = useLocation();

  const queryParams = queryString.parse(location.search);

  const setQueryParams = useCallback(
    ({ ...newParams }) => {
      const newKeys = Object.keys({ ...newParams });
      const query = queryString.stringify({
        ...Object.entries(queryParams)
          .filter((set) => newKeys.includes(set[0])) // removing current values, so they can be overwritten by new if new are nulls
          .reduce((obj, [key, value]) => ({ ...obj, [key]: value }), {}),
        ...newParams,
      });
      navigate(location.pathname + '?' + query);
    },
    [queryParams]
  );

  return { queryParams, setQueryParams };
}
