import React from 'react';
import ReactDatePicker from 'react-datepicker';
import { useLanguage } from 'src/hooks';
import DatePickerWrapper from 'src/theme/libs/react-datepicker';
import { LOCALIZATION } from 'src/utils/constant';

function DatePicker({
  required,
  selected,
  onChange,
  showMonthDropdown,
  showYearDropdown,
  showTimeSelect,
  showTimeSelectOnly,
  showTimeInput,
  timeIntervals,
  timeInputLabel,
  minTime,
  maxTime,
  minDate,
  maxDate,
  dateFormat,
  timeFormat,
  customInput,
  customTimeInput,
  disabled,
}) {
  const { lang } = useLanguage();

  return (
    <DatePickerWrapper>
      <ReactDatePicker
        locale={(lang ?? LOCALIZATION.ru_RU).split('_')?.[0]}
        required={required}
        selected={selected}
        onChange={onChange}
        showMonthDropdown={showMonthDropdown}
        showYearDropdown={showYearDropdown}
        showTimeSelect={showTimeSelect}
        showTimeSelectOnly={showTimeSelectOnly}
        showTimeInput={showTimeInput}
        timeIntervals={timeIntervals}
        timeInputLabel={timeInputLabel}
        minTime={minTime}
        maxTime={maxTime}
        minDate={minDate}
        maxDate={maxDate}
        dateFormat={dateFormat}
        timeFormat={timeFormat}
        customInput={customInput}
        customTimeInput={customTimeInput}
        disabled={disabled}
      />
    </DatePickerWrapper>
  );
}

export default DatePicker;
