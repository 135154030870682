import { Box, useMediaQuery } from '@mui/material';
import moment from 'moment-timezone';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import CalendarWrapper from 'src/@core/styles/libs/fullcalendar';
import { useLanguage, useMeetings } from 'src/hooks';
import usePrograms from 'src/hooks/usePrograms';
import { getDateISOString } from 'src/utils/time';
import AddEventSidebar from './AddEventSidebar';
import Calendar from './Calendar';
import SidebarLeft from './SidebarLeft';

function CalendarPage({ projectUuid }) {
  const calendarsColor = {
    meeting: 'primary',
    program: 'info',
  };

  const user = useSelector((state) => state.auth.user);
  const { showTableNumber } = useSelector((state) => state.app.projectSettings || {});

  const { localizeText, localizeMessage, getLocalizationValue } = useLanguage();
  const { isLoading, data: allPrograms } = usePrograms({ projectUuid });
  const { isLoading: isLoadingMeetings, data: meetings } = useMeetings({ projectUuid, status: 'approved' });
  const [store, setStore] = useState({
    allEvents: [],
    events: [],
    selectedEvent: null,
    selectedCalendars: Object.keys(calendarsColor),
  });
  const [calendarApi, setCalendarApi] = useState(null);
  const [leftSidebarOpen, setLeftSidebarOpen] = useState(false);
  const [addEventSidebarOpen, setAddEventSidebarOpen] = useState(false);

  const joinedProgramUUIDs = user?.projectSettings?.[projectUuid]?.joinedProgramUUIDs || [];
  const programs = allPrograms?.filter((p) => joinedProgramUUIDs.includes(p.uuid));
  const userUuid = user?.uuid;

  const leftSidebarWidth = 260;
  const addEventSidebarWidth = 400;
  const mdAbove = useMediaQuery((theme) => theme.breakpoints.up('md'));

  const handleSelectEvent = (selectedEvent) => {
    setStore({ ...store, selectedEvent: selectedEvent });
  };

  const handleCalendarsUpdate = (selectedCalendar) => {
    let state = { ...store };
    const filterIndex = state.selectedCalendars.findIndex((i) => i === selectedCalendar);
    if (state.selectedCalendars.includes(selectedCalendar)) {
      state.selectedCalendars.splice(filterIndex, 1);
    } else {
      state.selectedCalendars.push(selectedCalendar);
    }
    if (state.selectedCalendars.length === 0) {
      state.events = [];
    } else {
      state.events = state.allEvents.filter((ev) => state.selectedCalendars.includes(ev.extendedProps.calendar));
    }
    setStore(state);
  };

  const handleAllCalendars = (value) => {
    if (value === true) {
      setStore({ ...store, selectedCalendars: Object.keys(calendarsColor), events: store.allEvents });
    } else {
      setStore({ ...store, selectedCalendars: [], events: [] });
    }
  };

  const dispatch = () => {};

  const handleLeftSidebarToggle = () => setLeftSidebarOpen(!leftSidebarOpen);
  const handleAddEventSidebarToggle = () => setAddEventSidebarOpen(!addEventSidebarOpen);

  useEffect(() => {
    if (!isLoading && !isLoadingMeetings) {
      const programEvents = programs?.map((program) => {
        const start = getDateISOString(moment.unix(program.dateStart));
        const end = getDateISOString(moment.unix(program.dateFinish));
        return {
          id: program.uuid,
          title: getLocalizationValue(program, 'name'),
          start,
          end,
          extendedProps: { calendar: 'program', start, end },
        };
      });
      const meetingEvents = meetings?.map((meeting) => {
        const start = getDateISOString(meeting.startTime);
        const end = getDateISOString(meeting.endTime);
        const email = (meeting.ownerUuid !== userUuid ? meeting.emailOwner : meeting.emailMember) || '';
        const telephone = (meeting.ownerUuid !== userUuid ? meeting.telephoneOwner : meeting.telephoneMember) || '';
        return {
          id: meeting.uuid,
          title: `${(meeting.ownerUuid !== userUuid ? meeting.companyOwner : meeting.companyMember) || ''} - ${
            (meeting.ownerUuid !== userUuid ? meeting.fullNameOwner : meeting.fullNameMember) || ''
          }`,
          start,
          end,
          extendedProps: { calendar: 'meeting', start, end, email, telephone },
        };
      });
      console.log({ programEvents });
      const allEvents = programEvents.concat(meetingEvents);
      setStore({ ...store, allEvents: allEvents, events: allEvents });
    }
  }, [isLoading, isLoadingMeetings]);

  return (
    <CalendarWrapper
      sx={{
        boxShadow: true ? 0 : 6,
        ...(true && { border: (theme) => `1px solid ${theme.palette.divider}` }),
      }}
    >
      <SidebarLeft
        store={store}
        mdAbove={mdAbove}
        dispatch={dispatch}
        calendarsColor={calendarsColor}
        leftSidebarOpen={leftSidebarOpen}
        leftSidebarWidth={leftSidebarWidth}
        handleSelectEvent={handleSelectEvent}
        handleAllCalendars={handleAllCalendars}
        handleCalendarsUpdate={handleCalendarsUpdate}
        handleLeftSidebarToggle={handleLeftSidebarToggle}
        // handleAddEventSidebarToggle={handleAddEventSidebarToggle}
      />

      <Box
        sx={{
          p: 5,
          pb: 0,
          flexGrow: 1,
          borderRadius: 1,
          boxShadow: 'none',
          backgroundColor: 'background.paper',
        }}
      >
        <Calendar
          store={store}
          dispatch={dispatch}
          direction={'ltr'}
          // updateEvent={updateEvent}
          calendarApi={calendarApi}
          calendarsColor={calendarsColor}
          setCalendarApi={setCalendarApi}
          handleSelectEvent={handleSelectEvent}
          handleLeftSidebarToggle={handleLeftSidebarToggle}
          handleAddEventSidebarToggle={handleAddEventSidebarToggle}
        />
      </Box>
      <AddEventSidebar
        store={store}
        dispatch={dispatch}
        // addEvent={addEvent}
        // updateEvent={updateEvent}
        // deleteEvent={deleteEvent}
        // calendarApi={calendarApi}
        drawerWidth={addEventSidebarWidth}
        handleSelectEvent={handleSelectEvent}
        addEventSidebarOpen={addEventSidebarOpen}
        handleAddEventSidebarToggle={handleAddEventSidebarToggle}
      />
    </CalendarWrapper>
  );
}

export default CalendarPage;
