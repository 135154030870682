import { Warning } from '@mui/icons-material';
import { Button, Container, Stack, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useLanguage } from 'src/hooks';
import classes from './alert-popup.module.sass';

const LIMIT_POPUP_TIME = 20 * 1000; // 20s

function ErrorPopup({ time = LIMIT_POPUP_TIME, setHasError }) {
  const { localizeMessage, localizeText } = useLanguage();

  let timeout;

  useEffect(() => {
    timeout = setTimeout(() => {
      setHasError(false);
    }, time);
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <Stack className={classes.root}>
      <Container sx={{ height: '100%' }}>
        <Stack
          justifyContent="center"
          alignItems="center"
          gap="16px"
          sx={{ height: '100%', width: { xs: '85%', md: '75%' }, margin: 'auto', textAlign: 'center' }}
        >
          <Warning color="warning" sx={{ fontSize: '48px' }} />
          <Typography variant="h4" fontWeight={700}>
            {localizeMessage.REGISTRATION_IS_NOT_AVAILABLE}
          </Typography>
          <Typography variant="h5">{localizeMessage.PLEASE_CONTACT_THE_CHECK_IN_DESK}</Typography>
          <Button
            variant="contained"
            color="secondary"
            sx={{ minWidth: '100px' }}
            onClick={() => {
              setHasError(false);
            }}
          >
            {localizeText.CLOSE}
          </Button>
        </Stack>
      </Container>
    </Stack>
  );
}

export default ErrorPopup;
