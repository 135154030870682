const TextField = () => {
  return {
    MuiFormControl: {
      styleOverrides: {
        root: {
          '& .MuiFormLabel-asterisk.MuiInputLabel-asterisk, & .MuiFormLabel-asterisk': {
            color: 'red',
            order: -1,
          },
          '& .MuiInputBase-input, & .MuiInputBase-root': {
            fontSize: '1.2rem',
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: '17px',
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontSize: '12.75px',
        },
      },
    },
  };
};

export default TextField;
