import { DraftsOutlined, PhoneOutlined } from '@mui/icons-material';
import { Avatar, Button, Stack, Tooltip, Typography } from '@mui/material';
import moment from 'moment-timezone';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import SlotButton from 'src/components/slot-button';
import { userAPIs } from '../../../../services';
import { EVENTS, LOCALIZATION, MESSAGES, TEXT } from '../../../../utils/constant';
import eventBus from '../../../../utils/eventBus';
import { DEFAULT_TIMEZONE } from '../../../../utils/time';
import classes from './user.module.sass';

const UserComponent = ({ projectUuid, user, isExpand }) => {
  const lang = useSelector((state) => state.app.lang) || LOCALIZATION.ru_RU;
  const [avatar, setAvatar] = useState(null);

  // const getAvatarMember = async (memberUuid) => {
  //   try {
  //     const rawAvatar = await userAPIs.getAvatarMemberVizits(memberUuid);
  //     setAvatar(rawAvatar);
  //   } catch (e) {
  //     console.log(e);
  //     return null;
  //   }
  // };

  // useEffect(() => {
  //   getAvatarMember(user.userUuid);
  // }, [user]);

  return (
    // <Stack className={classes.content}>
    //   <div className={classes.info}>
    //     <Stack direction="row" gap="12px" alignItems="center" sx={{ margin: '12px 0' }}>
    //       <Avatar src={avatar ? URL.createObjectURL(avatar) : ''} sx={{ width: 85, height: 85 }}>
    //         {(user?.fullName?.split(' ')?.[0]?.[0] || '') + (user?.fullName?.split(' ')?.[1]?.[0] || '')}
    //       </Avatar>
    //       <Stack>
    //         <Typography variant={'h6'} sx={{ fontWeight: 'bold' }}>
    //           {user.fullName}
    //         </Typography>
    //         <Typography variant={'h7'} sx={{ color: '#8A8A8E' }}>
    //           {user.role}
    //         </Typography>
    //         <Stack direction="row" gap="8px" marginTop="8px" flexWrap="wrap">
    //           <Tooltip title={<Typography sx={{ fontSize: '17px' }}>{user.telephone}</Typography>}>
    //             <PhoneOutlined />
    //           </Tooltip>
    //           <Tooltip title={<Typography sx={{ fontSize: '17px' }}>{user.email}</Typography>}>
    //             <DraftsOutlined />
    //           </Tooltip>
    //         </Stack>
    //         <Stack gap="8px" marginTop="8px">
    //           <Stack direction="row" gap="8px">
    //             <PhoneOutlined />
    //             <Typography>{user.telephone}</Typography>
    //           </Stack>
    //           <Stack direction="row" gap="8px">
    //             <DraftsOutlined />
    //             <Typography>{user.email}</Typography>
    //           </Stack>
    //         </Stack>
    //       </Stack>
    //     </Stack>
    //   </div>
    // </Stack>
    isExpand && (
      <>
        <Typography
          color="#8A8A8E"
          algin="justify"
          sx={{ margin: '8px 0', overflowWrap: 'break-word', hyphens: 'auto' }}
        >
          {user.description}
        </Typography>
        {user?.slots != null && user?.slots?.length !== 0 && (
          <div style={{ fontWeight: 'bold', margin: '10px 0' }}>{`${MESSAGES[lang].CHOOSE_MEETING_SLOT}:`}</div>
        )}
        <Stack direction="row" gap="6px" flexWrap="wrap" justifyContent="center">
          {(user.slots || []).map((slot, i) => (
            <SlotButton key={i} slot={slot} projectUuid={projectUuid} userVizit={user} validateSlot={true} />
          ))}
        </Stack>
      </>
    )
  );
};

export default UserComponent;
