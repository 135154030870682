import styled from '@emotion/styled';
import { AccessTime, LocationOn, Star, StarBorder } from '@mui/icons-material';
import { Button, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import moment from 'moment-timezone';
import { useSelector } from 'react-redux';
import { useLanguage } from 'src/hooks';
import { joinOrLeaveProgram } from 'src/store/actions/auth';
import { EVENTS } from 'src/utils/constant';
import eventBus from 'src/utils/eventBus';
import { getDateMoment } from 'src/utils/time';
import classes from './program.module.sass';

const RowContainer = styled(Stack)(({ theme }) => ({
  flexDirection: 'column',
  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
  },
}));

function ProgramList({ programs, projectUuid }) {
  const user = useSelector((state) => state.auth.user);

  const { localizeText, getLocalizationValue } = useLanguage();

  const joinedProgramUUIDs = user?.projectSettings?.[projectUuid]?.joinedProgramUUIDs || [];

  const handleJoinProgram = (programUuid) => {
    joinOrLeaveProgram(projectUuid, programUuid);
  };

  return (
    <Stack gap="8px">
      {programs.map((program, i) => {
        const name = getLocalizationValue(program, 'name');
        const location = getLocalizationValue(program, 'room');
        const isJoined = joinedProgramUUIDs.includes(program.uuid);
        const dateStart = getDateMoment(moment.unix(program?.dateStart));
        const dateFinish = getDateMoment(moment.unix(program?.dateFinish));

        return (
          <RowContainer
            key={i}
            justifyContent={{ xs: 'center', md: 'space-between' }}
            textAlign={{ xs: 'center', md: 'left' }}
            gap="16px"
            className={classes.programItem}
          >
            <RowContainer
              alignItems={{ xs: 'center', md: 'flex-start' }}
              gap="16px"
              sx={{ flex: { xs: '1 1 100%', md: '1 1 60%' } }}
            >
              <IconButton color="primary" sx={{ marginTop: '-4px' }} onClick={() => handleJoinProgram(program.uuid)}>
                {isJoined ? (
                  <Tooltip title={localizeText.JOINED}>
                    <Star fontSize="large" />
                  </Tooltip>
                ) : (
                  <Tooltip title={localizeText.JOIN}>
                    <StarBorder fontSize="large" />
                  </Tooltip>
                )}
              </IconButton>
              <Typography variant="h5" sx={{ hyphens: 'auto', fontWeight: '600', fontSize: '15px' }}>
                {name}
              </Typography>
            </RowContainer>
            <RowContainer gap="16px" justifyContent="space-between" sx={{ flex: { xs: '1 1 100%', md: '1 1 40%' } }}>
              <Stack gap="8px">
                {/* <Typography variant="body1">{dateStart.format('DD MMMM')}</Typography> */}
                <Stack direction="row" gap="4px">
                  <AccessTime color="primary" />
                  <div style={{ hyphens: 'auto' }}>{`${dateStart.format('HH:mm')} - ${dateFinish.format(
                    'HH:mm'
                  )}`}</div>
                </Stack>
                {location && (
                  <Stack direction="row" gap="4px">
                    <LocationOn color="primary" />
                    <div style={{ hyphens: 'auto' }}>{location}</div>
                  </Stack>
                )}
              </Stack>
              <Button
                variant="outlined"
                size="large"
                sx={{ borderRadius: '4px', height: 'fit-content' }}
                onClick={() => eventBus.emit(EVENTS.OPEN_PROGRAM_DETAIL_MODAL, { program })}
              >
                {localizeText.DETAIL}
              </Button>
            </RowContainer>
          </RowContainer>
        );
      })}
    </Stack>
  );
}

export default ProgramList;
