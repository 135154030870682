import CameraAltIcon from '@mui/icons-material/CameraAlt';
import ClearIcon from '@mui/icons-material/Clear';
import { Skeleton } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import React from 'react';
import { useSelector } from 'react-redux';
import { uploadAvatar } from '../../store/actions/auth';
import { EVENTS } from '../../utils/constant';
import eventBus from '../../utils/eventBus';
import classes from './avatar.module.sass';

export const MyAvatar = ({ sx = {}, hideUploadAvatar, hideOpenAvatar }) => {
  const isLoadingAvatar = useSelector((state) => state.auth.isLoadingAvatar);
  const user = useSelector((state) => state.auth.user);
  const inputFileRef = React.useRef();
  const removeImg = React.useRef();
  const [imgSrc, setImgSrc] = React.useState(null);
  // const isLocked = user?.isLocked;

  const handleOnChange = (e) => {
    const newImage = e.target?.files?.[0];
    if (newImage) {
      uploadAvatar(newImage);
      setImgSrc(URL.createObjectURL(newImage));
    }
  };

  const handleOpenFile = () => {
    if (user?.avatar && !hideOpenAvatar) {
      eventBus.emit(EVENTS.OPEN_VIEW_IMAGE_MODAL, { images: [{ src: user.avatar }], fileName: 'avatar.png' });
    }
  };

  const handleRemove = () => {
    URL.revokeObjectURL(imgSrc);
    inputFileRef.current.value = null;
    setImgSrc(null);
    removeImg.current.classList.remove('enable');
  };

  const handleMouseEnter = () => {
    if (imgSrc) removeImg.current.classList.add('enable');
  };

  const handleMouseLeave = () => {
    if (imgSrc) removeImg.current.classList.remove('enable');
  };

  return (
    <div className={classes.root}>
      <input
        ref={inputFileRef}
        accept="image/*"
        className={classes.inputAvatar}
        id="contained-button-file"
        multiple={false}
        type="file"
        onChange={handleOnChange}
      />
      <div
        className={classes.avatarWrapper}
        onClick={handleOpenFile}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {/* <label htmlFor="contained-button-file"> */}
        <label>
          {isLoadingAvatar ? (
            <Skeleton variant="circular" className={classes.avatar} />
          ) : (
            <Avatar src={user?.avatar} className={classes.avatar} sx={{ ...sx }}>
              {(user?.lastName?.[0] || '') + (user?.firstName?.[0] || '')}
            </Avatar>
          )}
        </label>
        {!hideUploadAvatar && (
          <label htmlFor="contained-button-file" className={classes.cameraLabel} onClick={(e) => e.stopPropagation()}>
            <CameraAltIcon />
          </label>
        )}
        <div className={classes.removeImg} ref={removeImg}>
          <div className="icon-wrapper" onClick={handleRemove}>
            <ClearIcon className="icon" />
          </div>
        </div>
      </div>
    </div>
  );
};
