import CloseIcon from '@mui/icons-material/Close';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { CircularProgress, Dialog, DialogContent, Divider, IconButton, Stack } from '@mui/material';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { EVENTS, LOCALIZATION } from '../../utils/constant';
import eventBus from '../../utils/eventBus';
import { DEFAULT_TIMEZONE, getDateMoment } from '../../utils/time';
import classes from './program-detail-modal.module.sass';

export const ProgramDetailModal = () => {
  const lang = useSelector((state) => state.app.lang) || LOCALIZATION.ru_RU;
  const [state, setState] = useState({
    show: false,
    isLoading: false,
    program: null,
    onOk: () => { },
  });

  const handleCancel = () => setState({ show: false });
  const handleShow = async (data) => {
    setState({ ...state, ...data, show: true });
  };

  useEffect(() => {
    eventBus.on(EVENTS.OPEN_PROGRAM_DETAIL_MODAL, handleShow);
    return () => {
      eventBus.off(EVENTS.OPEN_PROGRAM_DETAIL_MODAL, handleShow);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const membersByType =
    state.program?.members?.reduce((p, c) => {
      const type = c.localization?.[lang]?.type;
      return type ? { ...p, [type]: [...(p[type] || []), c] } : p;
    }, {}) || {};

  return (
    <>
      <Dialog
        sx={{
          '& .MuiDialog-paper': {
            minWidth: '50vw',
            minHeight: '60%',
            borderRadius: '30px',
            border: '4px solid #c1c1ff',
          },
        }}
        maxWidth="md"
        open={state.show}
        onClose={handleCancel}
      >
        <DialogContent>
          <IconButton
            aria-label="close"
            onClick={handleCancel}
            sx={{
              position: 'absolute',
              right: 16,
              top: 16,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          {state.isLoading ? (
            <div className={classes.loading}>
              <CircularProgress />
            </div>
          ) : (
            <div className={classes.body}>
              <div>
                <div className={classes.name}>{state.program?.localization?.[lang]?.name}</div>
                <Divider sx={{ borderWidth: '1.5px', borderColor: '#f0b210', width: 45 }} />
              </div>

              <Stack gap="8px">
                <div className={classes.date}>
                  {getDateMoment(moment.unix(state.program?.dateStart)).format('DD MMMM , HH:mm -') +
                    getDateMoment(moment.unix(state.program?.dateFinish)).format(' HH:mm')}
                </div>
                {state.program?.localization?.[lang]?.tag && (
                  <div className={classes.tag}>{state.program?.localization?.[lang]?.tag}</div>
                )}
                {state.program?.localization?.[lang]?.room && (
                  <Stack direction="row" gap="6px">
                    <LocationOnIcon />
                    <div className={classes.room}>{state.program?.localization?.[lang]?.room}</div>
                  </Stack>
                )}
              </Stack>

              <Divider />
              <div className={classes.desc} dangerouslySetInnerHTML={{ __html: state.program?.localization?.[lang]?.description }} />


              {Object.keys(membersByType).map((type, i) => (
                <Stack key={i} gap="4px">
                  <div className={classes.memberType}>{type}</div>
                  <Stack gap="4px">
                    {membersByType[type].map((member, iMember) => (
                      <div key={iMember}>
                        <span className={classes.memberName}>{member.localization?.[lang]?.name}</span>
                        {member.localization?.[lang]?.position && (
                          <span>, {member.localization?.[lang]?.position}</span>
                        )}
                      </div>
                    ))}
                  </Stack>
                </Stack>
              ))}
            </div>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};
