import { CheckOutlined } from '@mui/icons-material';
import { Button } from '@mui/material';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useLanguage } from 'src/hooks';
import { joinProject, leaveProject } from 'src/store/actions/app';
import { EVENTS } from 'src/utils/constant';
import eventBus from 'src/utils/eventBus';
import Loader from '../loader';

function JoinProjectButton({ size = 'medium', project, redirectUrl, hideJoinedText }) {
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.user);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const { localizeMessage, localizeText } = useLanguage();

  const isJoinedProject = user?.projectUuids?.includes(project.uuid);

  const handleJoinProject = async () => {
    setIsSubmitting(true);
    await joinProject(project.uuid);
    setIsSubmitting(false);
  };

  const handleLeaveProject = () => {
    eventBus.emit(EVENTS.OPEN_CONFIRM_MODAL, {
      showWarning: true,
      contentBold: true,
      content: localizeMessage.CONFIRM_LEAVE_PROJECT,
      okText: localizeText.LEAVE_PROJECT,
      onOk: async () => {
        try {
          setIsSubmitting(true);
          await leaveProject(project.uuid);
          if (redirectUrl) navigate(redirectUrl);
        } finally {
          setIsSubmitting(false);
        }
      },
    });
  };

  const handleToggleJoinProject = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (isJoinedProject) {
      handleLeaveProject();
    } else {
      handleJoinProject();
    }
  };
  return null;
  return (
    <Button
      size={size}
      variant="contained"
      color={isJoinedProject ? 'primary' : 'secondary'}
      startIcon={isSubmitting ? <Loader size="12px" color="inherit" /> : undefined}
      endIcon={isJoinedProject || hideJoinedText ? <CheckOutlined /> : undefined}
      onClick={handleToggleJoinProject}
      disabled={isSubmitting}
      sx={{
        '& .MuiButton-endIcon': {
          marginLeft: hideJoinedText ? 0 : '8px',
        },
        borderRadius: '30px',
      }}
    >
      {hideJoinedText ? '' : isJoinedProject ? localizeText.JOINED : localizeText.JOIN}
    </Button>
  );
}

export default JoinProjectButton;
