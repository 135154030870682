import authReducer from './auth';
import appReducer from './app';
import appChatReducer from '../apps/chat';

const combinedReducer = () => ({
  auth: authReducer,
  app: appReducer,
  chat: appChatReducer,
});

export default combinedReducer;
