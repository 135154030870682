import { useRef } from 'react';
import classes from './amount-toggle.module.sass';
const MIN = 0;
const MAX = 999;
export default function AmountToggle({ amount, onAdd, onDistract, handleCount, style }) {
  const inputRef = useRef();
  const handleAdd = () => {
    if (inputRef.current.value < inputRef.current.max) onAdd();
  };
  const handleDistract = (e) => {
    if (inputRef.current.value > inputRef.current.min) onDistract();
  };

  const handleChange = (e) => {
    let newVal = Number(e.target.value);
    if (newVal > MAX) newVal = MAX;
    else if (newVal < MIN) newVal = MIN;
    e.target.value = newVal;
    handleCount(newVal);
  };
  return (
    <div className={classes.root} style={style}>
      <div className={classes.minus} onClick={handleDistract}>
        -
      </div>
      <input
        ref={inputRef}
        className={classes.amount}
        onChange={handleChange}
        value={amount}
        min={MIN}
        max={MAX}
        type="number"
        onKeyDown={(e) => {
          if (['e', 'E', '+', '-'].includes(e.key)) {
            e.preventDefault();
          }
        }}
      />
      <div className={classes.plus} onClick={handleAdd}>
        +
      </div>
    </div>
  );
}
