import { Button } from '@mui/material';
import moment from 'moment-timezone';
import { useSelector } from 'react-redux';
import { userAPIs } from 'src/services';
import { EVENTS } from 'src/utils/constant';
import eventBus from 'src/utils/eventBus';
import { DEFAULT_TIMEZONE } from 'src/utils/time';

function SlotButton({ slot, projectUuid, userVizit, validateSlot, onSubmit = () => {} }) {
  const projectSettings = useSelector((state) => state.app.projectSettings);
  const allowIsBusySlot = projectSettings?.allowIsBusySlot;

  const handleClickAppointment = async (slot) => {
    // if (slot.isBusy) return;
    const body = { ...slot, vizitUuid: userVizit.vizitUuid, memberUuid: userVizit.userUuid };
    let error;
    let message;
    if (validateSlot) {
      try {
        const res = await userAPIs.validateMeeting(projectUuid, body);
        message = res.message;
      } catch (e) {
        error = e.message.msg;
      }
    }
    eventBus.emit(EVENTS.OPEN_CONFIRM_APPOINTMENT_MODAL, { ...userVizit, slot, error, message });
    onSubmit();
  };

  if (!slot) return null;
  const isBusy = allowIsBusySlot ? false : Boolean(slot.isBusy);
  return (
    <Button
      variant={isBusy ? 'outlined' : 'contained'}
      // className={`${classes.timeButton} ${slot.isBusy ? classes.slotBusy : ''}`}
      disabled={isBusy}
      // color={slot.isBusy ? 'secondary' : 'primary'}
      onClick={() => handleClickAppointment(slot)}
    >{`${moment.tz(slot.startTime, DEFAULT_TIMEZONE).format('DD/MM HH:mm')} - ${moment
      .tz(slot.endTime, DEFAULT_TIMEZONE)
      .format('HH:mm')}`}</Button>
  );
}

export default SlotButton;
