import { styled } from '@mui/material';
import React from 'react';
import MuiTooltip, { tooltipClasses } from '@mui/material/Tooltip';

const Tooltip = styled(({ className, ...props }) => <MuiTooltip {...props} classes={{ popper: className }} />)(
  ({ maxWidth }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: maxWidth ?? 300,
    },
  })
);

export default Tooltip;
