// ** React Imports
import { useState } from 'react';

// ** MUI Imports
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';

// ** Icon Imports
import Icon from 'src/@core/components/icon';
import { useSelector } from 'react-redux';
import { EVENTS, MESSAGES, TEXT } from 'src/utils/constant';
import eventBus from 'src/utils/eventBus';
import { fetchMeetingsInChat, selectChat } from 'src/store/apps/chat';
import { useParams } from 'react-router-dom';

// ** Styled Components
const ChatFormWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  boxShadow: theme.shadows[1],
  padding: theme.spacing(1.25, 4),
  justifyContent: 'space-between',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.paper,
}));

const Form = styled('form')(({ theme }) => ({
  padding: theme.spacing(0, 5, 5),
}));

const SendMsgForm = (props) => {
  const { projectUuid } = useParams();
  // ** Props
  const { store, dispatch, sendMsg } = props;

  // ** State
  const [msg, setMsg] = useState('');
  const lang = useSelector((state) => state.app.lang);
  const selectedChat = useSelector((state) => state.chat.selectedChat);
  const userMemberVizits = useSelector((state) => state.chat.userMemberVizits);

  const handleSendMsg = (e) => {
    e.preventDefault();
    if (store && store.selectedChat && msg.trim().length) {
      dispatch(sendMsg({ ...store.selectedChat, message: msg }));
    }
    setMsg('');
  };

  return (
    <Form onSubmit={handleSendMsg}>
      <ChatFormWrapper>
        <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
          <TextField
            fullWidth
            value={msg}
            size="small"
            placeholder={MESSAGES[lang].TYPE_MESSAGE}
            onChange={(e) => setMsg(e.target.value)}
            sx={{ '& .MuiOutlinedInput-input': { pl: 0 }, '& fieldset': { border: '0 !important' } }}
          />
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          {/* <IconButton size="small" sx={{ color: 'text.primary' }}>
            <Icon icon="mdi:microphone" fontSize="1.375rem" />
          </IconButton>
          <IconButton size="small" component="label" htmlFor="upload-img" sx={{ mr: 4, color: 'text.primary' }}>
            <Icon icon="mdi:attachment" fontSize="1.375rem" />
            <input hidden type="file" id="upload-img" />
          </IconButton> */}

          <Button
            variant="outlined"
            sx={{ borderRadius: '4px', whiteSpace: 'nowrap', padding: '6px 8px' }}
            onClick={() =>
              eventBus.emit(EVENTS.OPEN_SCHEDULE_MEETING, {
                user: {
                  ...selectedChat?.contact,
                  chatUuid: selectedChat?.chat?.id,
                },
                callback: () => {
                  dispatch(selectChat(selectedChat?.chat?.id));
                  dispatch(fetchMeetingsInChat({ projectUuid, chatUuid: selectedChat?.chat?.id }));
                },
              })
            }
          >
            {TEXT[lang].SCHEDULE_MEETING}
          </Button>
          <Button
            type="submit"
            variant="contained"
            sx={{ borderRadius: '4px', whiteSpace: 'nowrap', padding: '6px 8px' }}
          >
            {TEXT[lang].SEND}
          </Button>
        </Box>
      </ChatFormWrapper>
    </Form>
  );
};

export default SendMsgForm;
