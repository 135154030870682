import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import { Stack, TableSortLabel as MuiTableSortLabel, Typography } from '@mui/material';
import { ORDER_DIRECTION } from 'src/utils/constant';

function TableSortLabel({ headCell, order, orderBy, onRequestSort = () => {} }) {
  const isSorted = orderBy === headCell.id;

  return (
    <MuiTableSortLabel
      // active={orderBy === headCell.id && order}
      onClick={() => onRequestSort(headCell.id)}
      direction={(isSorted ? order : ORDER_DIRECTION.ASC).toLowerCase()}
      hideSortIcon={true}
    >
      <Stack direction="row">
        <Typography variant="body1" sx={{ fontSize: '0.75rem', fontWeight: 600, lineHeight: '24px' }}>
          {headCell.label}
        </Typography>

        <Stack sx={{ width: '0.75em', position: 'relative' }}>
          <ArrowDropUp
            fontSize="large"
            sx={{ position: 'absolute', top: '-1px' }}
            color={order === ORDER_DIRECTION.ASC && isSorted ? 'primary' : 'disabled'}
          />
          <ArrowDropDown
            fontSize="large"
            sx={{ position: 'absolute', bottom: '-1px' }}
            color={order === ORDER_DIRECTION.DESC && isSorted ? 'primary' : 'disabled'}
          />
        </Stack>
      </Stack>
    </MuiTableSortLabel>
  );
}

export default TableSortLabel;
