const Autocomplete = () => {
  return {
    MuiSvgIcon: {
      styleOverrides: {
        root: ({ theme }) => ({
          // color: theme.palette.action.active,
          fontSize: '20px',
          '& path': {
            color: 'inherit',
          },
        }),
      },
    },
  };
};

export default Autocomplete;
