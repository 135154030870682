import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import moment from 'moment-timezone';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getTableOptions } from 'src/pages/vizits/components/business-card';
import { DEFAULT_UTC_OFFSET, EVENTS, MESSAGES, TEXT } from 'src/utils/constant';
import eventBus from 'src/utils/eventBus';
import { timeZone, timeZoneConverter } from 'src/utils/time';
import DatePicker from '../date-picker';
import SlotButton from '../slot-button';

function ScheduleMeetingModal({ projectUuid }) {
  const [state, _setState] = useState({
    isOpen: false,
    user: null,
    callback: () => {},
  });
  const setState = (obj) => _setState((old) => ({ ...old, ...obj }));
  const lang = useSelector((state) => state.app.lang);
  const { showTableNumber } = useSelector((state) => state.app.projectSettings || {});

  const [slots, setSlots] = useState(state.user?.slots || []);
  const [timeSlot, setTimeSlot] = useState({
    tableNumber: '',
    date: moment().format('YYYY-MM-DD'),
    slot: '',
    fromTime: '',
    toTime: '',
    duration: 0,
  });

  const handleOpen = ({ user, callback = () => {} }) => {
    setState({ user, callback, isOpen: true });
    setSlots(user?.slots || []);
  };

  const handleClose = () => {
    setState({ isOpen: false, user: null });
  };

  const handleAddSlot = () => {
    const { fromTime, toTime, date, tableNumber } = timeSlot;
    const fromTimeMoment = moment(fromTime);
    const toTimeMoment = moment(toTime);
    const start = moment(date).format('DD-MM-YYYY ') + fromTimeMoment.format('HH:mm');
    const end = moment(date).format('DD-MM-YYYY ') + toTimeMoment.format('HH:mm');
    const _start = moment(start, 'DD-MM-YYYY HH:mm').unix() * 1000;
    const _end = moment(end, 'DD-MM-YYYY HH:mm').unix() * 1000;
    const startTime = timeZoneConverter(moment(_start), DEFAULT_UTC_OFFSET, timeZone(moment(_start).toDate()));
    const endTime = timeZoneConverter(moment(_end), DEFAULT_UTC_OFFSET, timeZone(moment(_end).toDate()));
    const newSlot = {
      tableNumber: tableNumber || 0,
      startTime: startTime.unix() * 1000,
      endTime: endTime.unix() * 1000,
    };
    eventBus.emit(EVENTS.OPEN_CONFIRM_APPOINTMENT_MODAL, { ...state.user, slot: newSlot, callback: state.callback });
    handleClose();
  };

  useEffect(() => {
    eventBus.on(EVENTS.OPEN_SCHEDULE_MEETING, handleOpen);
    return function cleanup() {
      eventBus.off(EVENTS.OPEN_SCHEDULE_MEETING, handleOpen);
    };
  }, []);

  const ableAddSlot =
    timeSlot &&
    (!showTableNumber || timeSlot.tableNumber) &&
    timeSlot.date &&
    (timeSlot.slot ||
      (timeSlot.fromTime && timeSlot.toTime && moment(timeSlot.fromTime).isBefore(moment(timeSlot.toTime))));

  const user = state.user;
  if (!user) return null;
  return (
    <Dialog open={state.isOpen} onClose={handleClose} PaperProps={{ sx: { overflow: 'unset' } }}>
      <DialogTitle>{TEXT[lang].SCHEDULE_MEETING}</DialogTitle>
      <DialogContent sx={{ maxHeight: '60vh', overflow: 'auto' }}>
        <Stack alignItems="center" gap="8px" sx={{ minWidth: '300px', padding: '16px' }}>
          <Typography
            sx={{ fontWeight: 'bold', marginBottom: '10px' }}
          >{`${MESSAGES[lang].CHOOSE_MEETING_SLOT}:`}</Typography>
          <Stack direction="row" gap="6px" flexWrap="wrap" justifyContent="center">
            {(slots || []).map((slot, i) => (
              <SlotButton key={i} projectUuid={projectUuid} userVizit={user} slot={slot} onSubmit={handleClose} />
            ))}
          </Stack>

          <Grid container spacing={4} sx={{ marginTop: '10px' }} justifyContent="center">
            {showTableNumber && (
              <Grid item xs={12} md={2}>
                <FormControl style={{ width: '100%' }}>
                  <InputLabel shrink={true} variant="standard">
                    {TEXT[lang].TABLE}
                  </InputLabel>

                  <Select variant="standard" margin="dense" value={timeSlot?.tableNumber}>
                    {getTableOptions().map((option, i) => (
                      <MenuItem
                        key={i}
                        value={option}
                        onClick={() => setTimeSlot({ ...timeSlot, tableNumber: option })}
                      >
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )}
            <Grid item xs={12} md={4}>
              <TextField
                type="date"
                label={TEXT[lang].DATE}
                sx={{ width: '100%' }}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  sx: {
                    '& .Mui-disabled': {
                      fill: '#000 !important',
                      WebkitTextFillColor: '#000 !important',
                    },
                  },
                }}
                variant="standard"
                value={timeSlot?.date}
                onChange={(e) => setTimeSlot((old) => ({ ...old, date: e.target.value }))}
              />
            </Grid>

            <Grid item xs={12} md={3}>
              <DatePicker
                selected={timeSlot.fromTime}
                onChange={(date) => setTimeSlot({ ...timeSlot, fromTime: date })}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={60}
                dateFormat="h:mm aa"
                minTime={moment().set('h', 0).set('minute', 0).set('second', 0).toDate()}
                maxTime={moment().set('h', 23).set('minute', 0).set('second', 0).toDate()}
                customInput={
                  <TextField label={TEXT[lang].FROM} variant="standard" fullWidth InputLabelProps={{ shrink: true }} />
                }
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <DatePicker
                selected={timeSlot.toTime}
                onChange={(date) => setTimeSlot({ ...timeSlot, toTime: date })}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={60}
                dateFormat="h:mm aa"
                minTime={
                  timeSlot.fromTime
                    ? moment(timeSlot.fromTime).add(1, 'hour').toDate()
                    : moment().set('h', 0).set('minute', 0).set('second', 0).toDate()
                }
                maxTime={moment().set('h', 23).set('minute', 0).set('second', 0).toDate()}
                customInput={
                  <TextField
                    label={TEXT[lang].TO}
                    variant="standard"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    error={Boolean(
                      timeSlot.fromTime &&
                        timeSlot.toTime &&
                        moment(timeSlot.fromTime).isSameOrAfter(moment(timeSlot.toTime), 'hour')
                    )}
                    helperText={
                      timeSlot.fromTime &&
                      timeSlot.toTime &&
                      moment(timeSlot.fromTime).isSameOrAfter(moment(timeSlot.toTime), 'hour')
                        ? 'Неверное время'
                        : ''
                    }
                  />
                }
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleAddSlot}
                disabled={!ableAddSlot}
                sx={{ whiteSpace: 'nowrap', width: '100%' }}
              >
                {TEXT[lang].ADD_SLOT}
              </Button>
            </Grid>
          </Grid>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleClose}>
          {TEXT[lang].CANCEL}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ScheduleMeetingModal;
