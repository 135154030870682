import { Close } from '@mui/icons-material';
import { Box, Dialog, DialogContent, DialogTitle, IconButton, Stack, Typography } from '@mui/material';
import CalendarWrapper from 'src/@core/styles/libs/fullcalendar';
import { getDateMoment } from 'src/utils/time';

function MoreEventsCalendarModal({ open, events, date, calendarsColor, onClose, onClickEvent }) {
  return (
    <Dialog maxWidth="md" open={open} onClose={onClose}>
      <DialogTitle>
        <Typography variant="span">{getDateMoment(date).format('LL')}</Typography>
        <IconButton sx={{ position: 'absolute', right: '1rem', top: '1rem' }} onClick={onClose}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers sx={{ minWidth: '30vw', maxHeight: '70vh' }}>
        <CalendarWrapper>
          <Stack className="fc" gap="8px" width="100%">
            {events.map((event, i) => {
              const colorName = calendarsColor[event?.event?.extendedProps?.calendar];
              return (
                <Stack
                  key={i}
                  className={`fc-event fc-event-start fc-event-end fc-event-future fc-daygrid-event fc-daygrid-dot-event bg-${colorName}`}
                  sx={{
                    padding: '8px',
                    width: '100%',
                    cursor: 'pointer',
                    '&:hover': {
                      boxShadow: (theme) => theme.shadows[6],
                    },
                  }}
                  alignItems="start"
                  onClick={() => onClickEvent(event?.event)}
                >
                  <Box className="fc-event-time" sx={{ fontSize: '1rem' }}>
                    {getDateMoment(event?.event?.extendedProps?.start).format('HH:mm')} -{' '}
                    {getDateMoment(event?.event?.extendedProps?.end).format('HH:mm')}
                  </Box>
                  <Box className="fc-event-title" sx={{ whiteSpace: 'normal', fontSize: '1rem' }}>
                    {event?.event?.title}
                  </Box>
                </Stack>
              );
            })}
          </Stack>
        </CalendarWrapper>
      </DialogContent>
    </Dialog>
  );
}

export default MoreEventsCalendarModal;
