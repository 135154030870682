import { Close } from '@mui/icons-material';
import { Button, Grid, Stack, Typography } from '@mui/material';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const createErrorNotification = (message, options = { type: 'error' }) => {
  toast(message, { position: toast.POSITION.TOP_RIGHT, ...options });
};

export const createNotification = (message, options = { type: 'success' }) => {
  toast(message, { position: toast.POSITION.TOP_RIGHT, ...options });
};

export const createCustomNotification = (
  message,
  buttonElement = { message: '', onClick: () => {} },
  options = {
    type: 'success',
    autoClose: true,
    description: '',
  }
) => {
  toast(
    ({ closeToast, toastProps }) => (
      <Stack direction="row" alignItems="center" justifyContent="space-between" gap="4px">
        <Stack>
          <Typography variant="body2" fontWeight={options.description ? 700 : 500}>
            {message}
          </Typography>
          {options.description && <Typography variant="subtitle2">{options.description}</Typography>}
        </Stack>
        {buttonElement.message && (
          <Button size="small" sx={{ whiteSpace: 'nowrap' }} onClick={buttonElement.onClick}>
            {buttonElement.message}
          </Button>
        )}
      </Stack>
    ),
    {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: options.autoClose,
      type: options.type,
    }
  );
};
