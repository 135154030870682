import * as constants from '../constants/auth';

const initialState = {
  user: null,
  error: false,
  passport: null,
  isLoadingAvatar: false,
  isLoadingProjects: false,
  projects: [],
  company: null,
  myOrders: [],
  archivedOrders: [],
  scanningCount: 0,
  isNewAccount: false,
  newProjectUuid: '',
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case constants.AUTH__LOGIN_SUCCESS:
      return {
        ...state,
        user: action.user,
      };
    case constants.AUTH__ERROR:
      return {
        ...state,
        error: action.status,
      };
    case constants.AUTH__LOGOUT:
      return initialState;
    case constants.AUTH__SET_PASSPORT:
      return {
        ...state,
        passport: action.passport,
      };
    case constants.AUTH__SET_LOADING_AVATAR:
      return { ...state, isLoadingAvatar: action.loading };
    case constants.AUTH__SET_AVATAR:
      return {
        ...state,
        user: { ...state.user, avatar: action.avatar },
      };
    case constants.AUTH__SET_PROJECTS:
      return {
        ...state,
        projects: action.projects,
      };
    case constants.AUTH__UPDATE_USER:
      return {
        ...state,
        user: { ...state.user, ...action.user },
      };
    case constants.AUTH__SET_LOADING:
      return {
        ...state,
        [action.dataType]: action.loading,
      };
    case constants.AUTH__SET_COMPANY:
      return {
        ...state,
        company: action.company,
      };
    case constants.AUTH__SET_MY_ORDERS:
      return {
        ...state,
        myOrders: action.myOrders,
      };
    case constants.AUTH__SET_ARCHIVED_ORDERS:
      return {
        ...state,
        archivedOrders: action.archivedOrders,
      };
    case constants.AUTH__SET_SCANNING_COUNT:
      return {
        ...state,
        scanningCount: action.scanningCount,
      };
    case constants.AUTH__SET_IS_NEW_ACCOUNT:
      return { ...state, isNewAccount: action.isNewAccount, newProjectUuid: action.newProjectUuid };
    default:
      return state;
  }
}
