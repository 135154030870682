import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import Error500 from './500';

function Fallback({ error }) {
  return <Error500 error={error} />;
}

function ErrorBoundaryWrapper({ children }) {
  return <ErrorBoundary FallbackComponent={Fallback}>{children}</ErrorBoundary>;
}

export default ErrorBoundaryWrapper;
