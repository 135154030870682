import MuiChip from '@mui/material/Chip';
import { useSelector } from 'react-redux';
import { VIZIT_STATUS } from '../../utils/constant';

const getStatusLabel = (status, lang) => {
  return VIZIT_STATUS?.[status]?.[lang];
  // switch (status) {
  //   case 'declined':
  //     return 'отменена';
  //   case 'approved':
  //     return 'подтверждена';
  //   case 'initial':
  //   default:
  //     return 'ожидает';
  // }
};

const getStatusColor = (status) => {
  switch (status) {
    case 'approved':
      return 'success';
    default:
      return 'warning';
  }
};

export default function VizitStatus({ status }) {
  const lang = useSelector((state) => state.app.lang);
  const label = getStatusLabel(status, lang);
  const color = getStatusColor(status);
  return label ? <MuiChip label={label} skin="light" color={color} /> : <></>;
}
