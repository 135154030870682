import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Stack, TextField, Typography } from '@mui/material';
import moment from 'moment';
import { Controller, useForm } from 'react-hook-form';
import ButtonLoading from 'src/components/button-loading';
import DatePicker from 'src/components/date-picker';
import { useLanguage } from 'src/hooks';
import { userScheduleAPIs } from 'src/services';
import { ScheduleValidationSchema } from 'src/utils/formValidator';
import { createErrorNotification, createNotification } from 'src/utils/notifications';

function ScheduleForm({ projectUuid, type, selectedRow, onClose }) {
  const { lang, localizeText, localizeMessage } = useLanguage();

  const {
    control,
    formState: { errors, isSubmitting },

    register,
    handleSubmit,
  } = useForm({
    defaultValues: {
      name: selectedRow?.name ?? '',
      peopleCount: selectedRow?.peopleCount ?? 1,
      date: moment(selectedRow?.date).toDate() ?? '',
      location: selectedRow?.location ?? '',
    },
    resolver: yupResolver(ScheduleValidationSchema(lang)),
  });

  const handleSubmitForm = async (values) => {
    try {
      const date = new Date(values.date);
      if (selectedRow) {
        await userScheduleAPIs.editSchedules(selectedRow.uuid, {
          ...values,
          date,
        });
      } else {
        await userScheduleAPIs.createSchedules({
          ...values,
          type,
          projectUuid,
          date,
        });
      }

      createNotification(localizeMessage.SAVE_SUCCESSFULLY);
    } catch (error) {
      console.error(error);
      createErrorNotification(localizeMessage.ERROR);
    } finally {
      onClose();
    }
  };

  return (
    <Stack
      sx={{ margin: '2rem auto', width: { xs: '100%', md: '50%' } }}
      alignItems="center"
      component="form"
      onSubmit={handleSubmit(handleSubmitForm)}
    >
      <Stack gap="24px" sx={{ width: '100%' }}>
        <Typography variant="h5" textAlign="center" textTransform="capitalize">
          {selectedRow ? localizeText.EDIT_SCHEDULE : localizeText.ADD_SCHEDULE}
        </Typography>

        <TextField
          variant="standard"
          label={localizeText.NAME}
          InputLabelProps={{ shrink: true }}
          {...register('name')}
        />
        <TextField
          required
          variant="standard"
          label={localizeText.NUMBER_OF_PEOPLE}
          type="number"
          InputLabelProps={{ shrink: true }}
          {...register('peopleCount')}
          error={!!errors.peopleCount}
          helperText={errors.peopleCount?.message}
        />
        <Controller
          name="date"
          control={control}
          render={({ field }) => (
            <DatePicker
              locale={lang?.split('_')?.[0] || 'ru'}
              required={true}
              selected={field.value || ''}
              onChange={(date) => {
                field.onChange(date);
              }}
              showTimeInput
              timeFormat="HH:mm"
              dateFormat="dd/MM/yyyy HH:mm"
              showYearDropdown
              showMonthDropdown
              timeInputLabel={localizeText.TIME}
              customInput={
                <TextField
                  InputLabelProps={{ shrink: true }}
                  variant="standard"
                  label={localizeText.DATE}
                  fullWidth={true}
                  error={!!errors.date}
                  helperText={errors.date?.message}
                />
              }
            />
          )}
        />

        <TextField
          required
          variant="standard"
          label={localizeText.LOCATION}
          InputLabelProps={{ shrink: true }}
          {...register('location')}
          error={!!errors.location}
          helperText={errors.location?.message}
        />

        <Stack direction="row" gap="24px">
          <Button variant="outlined" color="secondary" size="large" sx={{ width: '50%' }} onClick={onClose}>
            {localizeText.CANCEL}
          </Button>
          <ButtonLoading
            variant="contained"
            type="submit"
            size="large"
            sx={{ width: '50%' }}
            isLoading={isSubmitting}
            disabled={isSubmitting}
          >
            {localizeText.OK}
          </ButtonLoading>
        </Stack>
      </Stack>
    </Stack>
  );
}

export default ScheduleForm;
