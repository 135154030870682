import { ChevronLeft } from '@mui/icons-material';
import { IconButton, Stack, Tab, Tabs } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { a11yProps, TabPanel } from 'src/components';
import { useLanguage, useLocationManagement } from 'src/hooks';
import { useProjectData } from 'src/hooks/project';
import { SCHEDULE_TYPES } from 'src/utils/constant';
import TeamScheduleTable from './TeamScheduleTable';
import { useSelector } from 'react-redux';
import { useQuery } from 'react-query';
import { getMembersByProject } from 'src/store/actions/app';
import { useMemo, useState } from 'react';

function UserTeamSchedulePage({ project }) {
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);

  const currentUser = useSelector((state) => state.auth.user);

  const projectUuid = project?.uuid;
  const tabs = Object.values(SCHEDULE_TYPES);

  const { localizeText } = useLanguage();
  const { projectDetailURL } = useProjectData({ project: project });

  const { queryParams, setQueryParams } = useLocationManagement();
  const tab = tabs.indexOf(queryParams?.tab ?? SCHEDULE_TYPES.ARRIVAL);
  const {
    isLoading,
    isFetching,
    refetch: refetchMembers,
  } = useQuery(
    ['getMembersByProject', projectUuid, queryParams],
    async ({ queryKey }) => {
      const [, projectUuid, queryParams] = queryKey;
      const { members } = await getMembersByProject(
        {
          projectUuid,
          limit: 999,
          offset: 0,
        },
        true
      );
      setUsers(members);
    },
    { enabled: !!projectUuid }
  );

  const userList = useMemo(() => {
    return [currentUser, ...users];
  }, [users]);

  console.log('userList', userList);
  return (
    <Stack gap="1rem">
      {/* <Stack direction="row" flexWrap="wrap" gap="8px">
        <IconButton onClick={() => navigate(-1)}>
          <ChevronLeft />
        </IconButton>
        <h2 style={{ fontWeight: 500, margin: 0 }}>{`${localizeText.ARRIVAL}/${localizeText.DEPARTURE}`}</h2>
      </Stack> */}

      <Tabs value={tab} onChange={(_, value) => setQueryParams({ ...queryParams, tab: tabs[value], page: 1 })}>
        {tabs.map((tab, i) => (
          <Tab key={i} label={localizeText[tab.toUpperCase()]} {...a11yProps(i)} />
        ))}
      </Tabs>

      {tabs.map((_tab, i) => (
        <TabPanel key={i} value={tab} index={i}>
          <TeamScheduleTable
            currentUser={currentUser}
            users={userList}
            type={tabs[i]}
            projectUuid={projectUuid}
            queryParams={queryParams}
          />
        </TabPanel>
      ))}
    </Stack>
  );
}

export default UserTeamSchedulePage;
