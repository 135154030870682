import {
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import Loader from 'src/components/loader';
import { useLanguage } from 'src/hooks';
import { projectsAPIs } from 'src/services';
import { createErrorNotification, createNotification } from 'src/utils/notifications';
import StepFooter from './StepFooter';

function StepOptions({
  selectedProject,
  activeStep,
  steps,
  ticketNumber,
  setTicketNumber,
  accountOption,
  setAccountOption,
  checkAbleNext,
  handleNext,
  handlePrev,
}) {
  const { localizeMessage, localizeText } = useLanguage();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [src, setSrc] = useState('');
  const [notFound, setNotFound] = useState(false);

  const ACCOUNT_OPTIONS = {
    New: { label: localizeMessage.I_AM_A_NEW_VISITOR, value: 'new' },
    Old: { label: localizeMessage.I_ALREADY_HAVE_REGISTRATION, value: 'old' },
  };
  const accountOptions = Object.values(ACCOUNT_OPTIONS);
  const hasAlreadyRegistration = accountOption === ACCOUNT_OPTIONS.Old.value;

  useEffect(() => {
    if (!accountOption) {
      setAccountOption(ACCOUNT_OPTIONS.New.value);
    }
  }, [accountOption]);

  const handleSearch = async () => {
    try {
      setIsSubmitting(true);
      const response = await projectsAPIs.getUserTicket(selectedProject, ticketNumber);
      const srcURL = window.URL.createObjectURL(response);
      setSrc(srcURL);
      createNotification(localizeMessage.YOUR_TICKET_HAS_BEEN_SENT_WITHOUT_EMAIL);
    } catch (error) {
      console.error(error);
      setNotFound(true);
      createErrorNotification(localizeMessage.YOUR_TICKET_HAS_NOT_FOUND);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handlePrint = () => {
    document.getElementById('ticket-file').contentWindow.print();
  };

  return (
    <>
      <Stack marginY="20px" gap="8px" alignItems="center">
        <FormControl sx={{ minWidth: '50%' }}>
          <FormLabel
            sx={{
              color: (theme) => `${theme.palette.text.primary} !important`,
              fontSize: '20px',
              fontWeight: 'bold',
              marginBottom: '16px',
            }}
          >
            {localizeMessage.CHOOSE_ONE_OF_BELOW_OPTIONS}:
          </FormLabel>
          <RadioGroup onChange={(e) => setAccountOption(e.target.value)} value={accountOption}>
            {accountOptions.map((option, i) => (
              <FormControlLabel
                key={i}
                value={option.value}
                control={<Radio />}
                label={
                  <Typography variant="h6" fontWeight={400}>
                    {option.label}
                  </Typography>
                }
              />
            ))}
          </RadioGroup>
          {hasAlreadyRegistration && (
            <Stack gap="20px">
              <TextField
                name="ticketNumber"
                label={localizeMessage.ENTER_YOUR_TICKET_NUMBER}
                variant="standard"
                InputLabelProps={{ shrink: true }}
                sx={{ marginTop: '16px' }}
                value={ticketNumber}
                onChange={(e) => {
                  if (notFound) setNotFound(false);
                  setTicketNumber(e.target.value);
                  setSrc('');
                }}
                error={notFound}
                helperText={
                  notFound ? localizeMessage.YOUR_TICKET_HAS_NOT_FOUND : `${localizeText.EXAMPLE}: 6371819149462`
                }
              />
              <Button
                variant="contained"
                disabled={!ticketNumber || isSubmitting || notFound}
                startIcon={isSubmitting ? <Loader size="12px" color="grey" /> : <></>}
                onClick={() => {
                  src ? handlePrint() : handleSearch();
                }}
              >
                {src ? localizeText.PRINT : localizeText.SEARCH}
              </Button>
            </Stack>
          )}
        </FormControl>
      </Stack>
      <StepFooter
        activeStep={activeStep}
        steps={steps}
        checkAbleNext={checkAbleNext}
        handlePrev={handlePrev}
        handleNext={handleNext}
      />
      <iframe id="ticket-file" src={src} style={{ display: 'none' }} />
    </>
  );
}

export default StepOptions;
