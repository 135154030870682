import { yupResolver } from '@hookform/resolvers/yup';
import CloseIcon from '@mui/icons-material/Close';
import {
  Button,
  ClickAwayListener,
  FormControl,
  FormHelperText,
  Grid,
  Grow,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import MuiPhoneNumber from 'material-ui-phone-number';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { userAPIs } from '../../services';
import { addCompany, deleteUserCompany } from '../../store/actions/auth';
import { COMPANY_TYPE_OPTIONS, EVENTS, LOCALIZATION, TEXT } from '../../utils/constant';
import eventBus from '../../utils/eventBus';
import { companyValidationSchema } from '../../utils/formValidator';
import classes from './company-form.module.sass';
import { useDebounce } from 'use-debounce';
import { Search } from '@mui/icons-material';

let isTyping;
export function CompanyForm({ isLocked, isModal }) {
  const company = useSelector((state) => state.auth.company);
  const lang = useSelector((state) => state.app.lang) || LOCALIZATION.ru_RU;
  const countries = useSelector((state) => state.app.countries);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: useMemo(
      () => ({
        name: company?.name,
        fullName: company?.fullName,
        extLegalAddress: company?.extLegalAddress,
        countryOfRegistration: company?.countryOfRegistration || 643,
        webAddress: company?.webAddress,
        phone: company?.phone,
        contactPerson: company?.contactPerson,
        email: company?.email,
        companyType: company?.companyType || Object.keys(COMPANY_TYPE_OPTIONS)[0],
        kpp: company?.kpp,
        inn: company?.inn,
      }),
      [company]
    ),
    resolver: yupResolver(companyValidationSchema(lang)),
  });

  const [focusingField, setFocusingField] = useState('');
  const [isSearching, setIsSearching] = useState(false);
  const [suggestions, setSuggestions] = useState([]);

  const nameRef = useRef(null);
  const fullNameRef = useRef(null);
  const contactPersonRef = useRef(null);
  const kppRef = useRef(null);
  const innRef = useRef(null);
  const extLegalAddressRef = useRef(null);

  const onSubmit = async (values) => {
    try {
      setIsSubmitting(true);
      const body = {
        ...values,
        countryOfRegistration: parseInt(values.countryOfRegistration),
      };
      await addCompany(body);
      setIsChanged(false);
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    const subscription = watch(() => setIsChanged(true));
    return () => subscription.unsubscribe();
  }, [watch]);

  const getSuggestion = async (query, field = '', isAddress) => {
    if (isSearching) return;
    try {
      setIsSearching(true);
      const res = isAddress ? await userAPIs.getSuggestionAddress(query) : await userAPIs.getSuggestionCompany(query);
      setSuggestions(res.message || []);
      setFocusingField(field);
    } catch (e) {
      console.log(e);
    } finally {
      setIsSearching(false);
    }
  };

  const handleDeleteUserCompany = () => {
    eventBus.emit(EVENTS.OPEN_CONFIRM_MODAL, {
      onOk: () => {
        deleteUserCompany();
      },
    });
  };

  const renderSuggestion = useCallback(
    (field, ref, isAddress) => {
      const handleSelect = (suggestion) => {
        setFocusingField('');
        if (isAddress) {
          suggestion && setValue('extLegalAddress', suggestion);
        } else {
          suggestion.shortName && setValue('name', suggestion.shortName);
          suggestion.fullName && setValue('fullName', suggestion.fullName);
          suggestion.contactPerson && setValue('contactPerson', suggestion.managerName);
          suggestion.kpp && setValue('kpp', suggestion.kpp);
          suggestion.inn && setValue('inn', suggestion.inn);
          suggestion.address && setValue('extLegalAddress', suggestion.address);
          suggestion.type &&
            setValue(
              'companyType',
              suggestion.type === 'LEGAL' ? Object.keys(COMPANY_TYPE_OPTIONS)[0] : Object.keys(COMPANY_TYPE_OPTIONS)[3],
              {
                shouldValidate: true,
                shouldDirty: true,
                shouldTouch: true,
              }
            );
          suggestion.emails?.[0] && setValue('email', suggestion.emails?.[0]);
          suggestion.phones?.[0] && setValue('phone', suggestion.phones?.[0]);
        }
      };

      return (
        <Popper
          open={focusingField === field}
          anchorEl={ref.current}
          placement="bottom-start"
          transition
          sx={{ zIndex: 9999 }}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === 'bottom-start' ? 'left top' : 'left bottom',
              }}
            >
              <Paper className={classes.suggestions}>
                <ClickAwayListener onClickAway={() => setFocusingField('')}>
                  <MenuList
                    sx={{
                      maxWidth: '50vw',
                      maxHeight: '450px',
                      overflow: 'scroll',
                    }}
                  >
                    {suggestions.map((suggest, i) => (
                      <MenuItem key={i} onClick={() => handleSelect(suggest)}>
                        {isAddress ? `${suggest}` : `${suggest.shortName} - ${suggest.managerName}`}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      );
    },
    [suggestions, focusingField]
  );

  const handleChangeTextField = (e, field, isAddress) => {
    const value = e.target.value;
    setValue(field, value);
    clearTimeout(isTyping);
    isTyping = setTimeout(() => {
      getSuggestion(value, field, isAddress);
    }, 1000);
  };

  const labelStyle = { fontSize: 17, fontFamily: 'Lato' };
  const inputStyle = { fontSize: 20, fontFamily: 'Lato' };
  return (
    <form className={classes.root} noValidate onSubmit={handleSubmit(onSubmit)}>
      <Typography position="relative" className={classes.title}>
        {company && !isModal && (
          <IconButton
            aria-label="close"
            onClick={handleDeleteUserCompany}
            sx={{
              position: 'absolute',
              right: 0,
              top: 0,
              zIndex: 1,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        )}
      </Typography>

      <Grid container columnSpacing={8} sx={{ '& > div > div': { width: '100%' } }}>
        <Grid item xs={12} md={6}>
          <TextField
            label={TEXT[lang].INN}
            margin="normal"
            InputLabelProps={{
              shrink: true,
              sx: {
                '& .MuiInputLabel-asterisk': {
                  color: 'red',
                },
              },
              style: labelStyle,
            }}
            inputRef={innRef}
            onInput={(e) => {
              e.target.value = e.target.value.replace(/\D/g, '').slice(0, 10);
            }}
            variant="standard"
            defaultValue={getValues('inn')}
            {...register('inn')}
            onChange={(e) => handleChangeTextField(e, 'inn')}
            error={errors.inn ? true : false}
            helperText={errors.inn?.message}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
          {renderSuggestion('inn', innRef)}
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label={TEXT[lang].KPP}
            margin="normal"
            InputLabelProps={{
              shrink: true,
              sx: {
                '& .MuiInputLabel-asterisk': {
                  color: 'red',
                },
              },
              style: labelStyle,
            }}
            inputRef={kppRef}
            onInput={(e) => {
              e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 9);
            }}
            type="number"
            variant="standard"
            defaultValue={getValues('kpp')}
            {...register('kpp')}
            onChange={(e) => handleChangeTextField(e, 'kpp')}
            error={errors.kpp ? true : false}
            helperText={errors.kpp?.message}
          />
          {renderSuggestion('kpp', kppRef)}
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            required
            label={TEXT[lang].NAME_COMPANY}
            margin="normal"
            fullWidth
            InputLabelProps={{
              shrink: true,
              sx: {
                '& .MuiInputLabel-asterisk': {
                  color: 'red',
                },
              },
              style: labelStyle,
            }}
            inputRef={nameRef}
            InputProps={{
              sx: {
                '& input': {
                  textTransform: 'capitalize',
                },
              },
            }}
            variant="standard"
            autoFocus
            defaultValue={getValues('name')}
            {...register('name')}
            onChange={(e) => handleChangeTextField(e, 'name')}
            error={errors.name ? true : false}
            helperText={errors.name?.message}
          />
          {renderSuggestion('name', nameRef)}
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            required
            label={TEXT[lang].FULLNAME_COMPANY}
            margin="normal"
            InputLabelProps={{
              shrink: true,
              sx: {
                '& .MuiInputLabel-asterisk': {
                  color: 'red',
                },
              },
              style: labelStyle,
            }}
            inputRef={fullNameRef}
            variant="standard"
            defaultValue={getValues('fullName')}
            {...register('fullName')}
            onChange={(e) => handleChangeTextField(e, 'fullName')}
            error={errors.fullName ? true : false}
            helperText={errors.fullName?.message}
          />
          {renderSuggestion('fullName', fullNameRef)}
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            required
            label={TEXT[lang].ADDRESS_COMPANY}
            margin="normal"
            InputLabelProps={{
              shrink: true,
              sx: {
                '& .MuiInputLabel-asterisk': {
                  color: 'red',
                },
              },
              style: labelStyle,
            }}
            inputRef={extLegalAddressRef}
            variant="standard"
            defaultValue={getValues('extLegalAddress')}
            {...register('extLegalAddress')}
            onChange={(e) => handleChangeTextField(e, 'extLegalAddress', true)}
            error={errors.extLegalAddress ? true : false}
            helperText={errors.extLegalAddress?.message}
          />
          {renderSuggestion('extLegalAddress', extLegalAddressRef, true)}
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl margin="normal" error={errors.countryOfRegistration ? true : false}>
            <InputLabel
              required
              shrink={true}
              variant="standard"
              sx={{
                '& .MuiInputLabel-asterisk': {
                  color: 'red',
                },
                fontSize: 17,
              }}
            >
              {TEXT[lang].COUNTRY_COMPANY}
            </InputLabel>

            <Select
              variant="standard"
              margin="dense"
              defaultValue={getValues('countryOfRegistration')}
              {...register('countryOfRegistration')}
            >
              {countries.map((country) => (
                <MenuItem key={country.code} value={country.code}>
                  {country.localization?.[lang]?.name}
                  {country.shortNames?.[0] && ` (${country.shortNames?.[0]})`}
                </MenuItem>
              ))}
            </Select>
            {errors.countryOfRegistration && (
              <FormHelperText sx={{ marginLeft: 0, marginRight: 0 }} error={true}>
                {errors.countryOfRegistration.message}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            required
            label={TEXT[lang].SITE_COMPANY}
            margin="normal"
            InputLabelProps={{
              shrink: true,
              sx: {
                '& .MuiInputLabel-asterisk': {
                  color: 'red',
                },
              },
              style: labelStyle,
            }}
            variant="standard"
            defaultValue={getValues('webAddress')}
            {...register('webAddress')}
            error={errors.webAddress ? true : false}
            helperText={errors.webAddress?.message}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <MuiPhoneNumber
            required
            label={TEXT[lang].TELEPHONE_COMPANY}
            margin="normal"
            InputLabelProps={{
              shrink: true,
              sx: {
                '& .MuiInputLabel-asterisk': {
                  color: 'red',
                },
              },
              style: labelStyle,
            }}
            InputProps={{
              style: inputStyle,
            }}
            name="phone"
            variant="standard"
            autoFormat={false}
            defaultCountry={'ru'}
            {...register('phone')}
            value={getValues('phone')}
            onChange={(value) => setValue('phone', value)}
            error={errors.phone ? true : false}
            helperText={errors.phone?.message}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            required
            label={TEXT[lang].CONTACT_PERSON}
            margin="normal"
            InputLabelProps={{
              shrink: true,
              sx: {
                '& .MuiInputLabel-asterisk': {
                  color: 'red',
                },
              },
              style: labelStyle,
            }}
            inputRef={contactPersonRef}
            InputProps={{
              style: inputStyle,
            }}
            variant="standard"
            defaultValue={getValues('contactPerson')}
            {...register('contactPerson')}
            onChange={(e) => handleChangeTextField(e, 'contactPerson')}
            error={errors.contactPerson ? true : false}
            helperText={errors.contactPerson?.message}
          />
          {renderSuggestion('contactPerson', contactPersonRef)}
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            required
            label={TEXT[lang].EMAIL_COMPANY}
            margin="normal"
            InputLabelProps={{
              shrink: true,
              sx: {
                '& .MuiInputLabel-asterisk': {
                  color: 'red',
                },
              },
              style: labelStyle,
            }}
            InputProps={{
              style: inputStyle,
            }}
            variant="standard"
            {...register('email')}
            error={errors.email ? true : false}
            helperText={errors.email?.message}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl margin="normal" error={errors.companyType ? true : false}>
            <InputLabel
              required
              shrink={true}
              variant="standard"
              sx={{
                '& .MuiInputLabel-asterisk': {
                  color: 'red',
                },
                fontSize: 17,
              }}
            >
              {TEXT[lang].TYPE_COMPANY}
            </InputLabel>

            <Select
              variant="standard"
              margin="dense"
              defaultValue={getValues('companyType')}
              {...register('companyType')}
            >
              {Object.keys(COMPANY_TYPE_OPTIONS).map((type) => (
                <MenuItem key={type} value={type}>
                  {COMPANY_TYPE_OPTIONS[type]?.[lang]}
                </MenuItem>
              ))}
            </Select>
            {errors.companyType && (
              <FormHelperText sx={{ marginLeft: 0, marginRight: 0 }} error={true}>
                {errors.companyType.message}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
      </Grid>

      <div className={classes.button}>
        <Button
          type="submit"
          className={classes.submitButton}
          variant="contained"
          size="large"
          disabled={isSubmitting || !isChanged || isLocked}
        >
          {TEXT[lang].SAVE_CHANGES}
        </Button>
      </div>
    </form>
  );
}
