import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Link,
  Stack,
  Tooltip,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { QRCodeSVG } from 'qrcode.react';
import eventBus from '../../utils/eventBus';
import { EVENTS, LOCALIZATION, TEXT } from '../../utils/constant';
import { useSelector } from 'react-redux';
import { createNotification } from '../../utils/notifications';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

function QRCodeModal() {
  const lang = useSelector((state) => state.app.lang) || LOCALIZATION.ru_RU;

  const [state, setState] = useState({
    open: false,
    value: '',
  });

  const handelOpen = (value) => {
    setState({ open: true, value });
  };

  const handleClose = () => {
    setState({ open: false, value: '' });
  };

  useEffect(() => {
    eventBus.on(EVENTS.OPEN_QR_CODE_MODAL, handelOpen);
    return () => {
      eventBus.off(EVENTS.OPEN_QR_CODE_MODAL, handelOpen);
    };
  }, []);

  const link = state.value;

  return (
    <Dialog open={state.open} onClose={handleClose} PaperProps={{ sx: { width: { xs: '100vw', md: 'auto' } } }}>
      <DialogTitle>QR Code</DialogTitle>
      <DialogContent sx={{ minWidth: { xs: 'fit-content', md: '320px' } }}>
        <Stack justifyContent="center" alignItems="center" gap="16px" sx={{ width: '100%' }}>
          <QRCodeSVG value={state.value} height="200px" width="200px" />

          <Button
            endIcon={<ContentCopyIcon />}
            onClick={() => {
              try {
                navigator.clipboard.writeText(link);
                createNotification('Скопировано в буфер обмена');
              } catch (error) {
                console.error(error);
                createNotification('Что-то пошло не так', 'error');
              }
            }}
          >
            {TEXT[lang].COPY_SHARED_LINK}
          </Button>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{TEXT[lang].CANCEL}</Button>
      </DialogActions>
    </Dialog>
  );
}

export default QRCodeModal;
