import { Check } from '@mui/icons-material';
import { Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Loader from 'src/components/loader';
import { logout } from 'src/store/actions/auth';
import { MESSAGES } from 'src/utils/constant';
import { request } from 'src/utils/request';
import StepFooter from './StepFooter';

function StepFour({
  selectedProject,
  activeStep,
  steps,
  setActiveStep,
  handleNext = () => { },
  handlePrev = () => { },
  handleError = () => { },
}) {
  const user = useSelector((state) => state.auth.user);
  const lang = useSelector((state) => state.app.lang);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [src, setSrc] = useState('');

  const handleGenerateTicket = async () => {
    try {
      setIsSubmitting(true);
      const response = await request(
        `/users/current/projects/${selectedProject}/generate-ticket`,
        { method: 'POST' },
        true
      );
      setIsSuccess(true);
      const srcURL = window.URL.createObjectURL(response);
      setSrc(srcURL);
      setTimeout(() => {
        document.getElementById('ticket-file').contentWindow.print();
      }, 1000);
    } catch (error) {
      console.error(error);
      handleError();
    } finally {
      setIsSubmitting(false);
      setTimeout(() => {
        logout();
        setActiveStep(0);
      }, 20000);
    }
  };

  useEffect(() => {
    if (activeStep === steps.length - 1) {
      handleGenerateTicket();
    } else {
      setIsSubmitting(false);
      setIsSuccess(false);
      setSrc('');
    }
  }, [activeStep]);

  const handleSubmit = () => {
    logout();
    setActiveStep(0);
  };

  if (!user) return null;
  return (
    <>
      <Stack marginY="20px" alignItems="center">
        {isSubmitting ? (
          <Loader />
        ) : (
          <>
            <Check color="success" sx={{ fontSize: '64px' }} />
            <Typography variant="h6">
              {isSuccess
                ? MESSAGES[lang].YOUR_TICKET_HAS_BEEN_SENT
                : MESSAGES[lang].GENERATE_YOUR_TICKET}
            </Typography>
          </>
        )}
        <iframe id="ticket-file" src={src} style={{ display: 'none' }} />
      </Stack>
      <StepFooter
        activeStep={activeStep}
        steps={steps}
        isSubmitting={isSubmitting}
        handlePrev={handlePrev}
        handleNext={handleSubmit}
      />
    </>
  );
}

export default StepFour;
