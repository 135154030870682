import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { routes } from 'src/utils/routes';
import { authAPIs } from '../../services';
import { getTransMessages } from '../../utils/common';
import { EVENTS, LOCALIZATION, MESSAGES } from '../../utils/constant';
import eventBus from '../../utils/eventBus';
import { loginWithoutPasswordValidationSchema } from '../../utils/formValidator';
import { createErrorNotification } from '../../utils/notifications';

export const ForgotPasswordForm = () => {
  const navigate = useNavigate();
  const lang = useSelector((state) => state.app.lang) || LOCALIZATION.ru_RU;
  const [state, setState] = useState({
    show: false,
  });

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: { email: '' },
    resolver: yupResolver(loginWithoutPasswordValidationSchema(lang)),
  });

  const handleCancel = () => {
    reset();
    setState({ show: false });
  };

  const handleShow = (data) => {
    setState({ ...state, ...data, show: true });
  };

  const handleOk = async ({ email }) => {
    const transMessages = getTransMessages();
    try {
      await authAPIs.forgotPassword({ email });
    } catch (e) {
      console.log(e);
      if (e?.message?.error?.includes('not found')) {
        navigate(routes.register.path, { state: { error: MESSAGES[lang].ERROR_MESSAGE_FORGOT_PASSWORD_EMAIL } });
      } else {
        createErrorNotification(transMessages.ERROR);
      }
    } finally {
      handleCancel();
    }
  };

  useEffect(() => {
    eventBus.on(EVENTS.OPEN_FORGOT_PASSWORD_FORM, handleShow);
    return () => {
      eventBus.off(EVENTS.OPEN_FORGOT_PASSWORD_FORM, handleShow);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const labelStyle = { fontSize: 17, fontFamily: 'Lato' };
  const inputStyle = { fontSize: 20, fontFamily: 'Lato' };
  return (
    <>
      <Dialog
        sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
        maxWidth="xs"
        open={state.show}
        onClose={handleCancel}
      >
        <DialogTitle>Забыл пароль</DialogTitle>
        <DialogContent sx={{ padding: '20px 24px !important' }}>
          <form id="forgot-password-form" onSubmit={handleSubmit(handleOk)}>
            <TextField
              label="E-mail"
              InputLabelProps={{
                shrink: true,
                style: labelStyle,
              }}
              InputProps={{
                style: inputStyle,
              }}
              sx={{ width: '100%' }}
              autoFocus
              variant="standard"
              {...register('email')}
              error={errors.email ? true : false}
              helperText={errors.email?.message}
            />
          </form>
        </DialogContent>
        <DialogActions sx={{ padding: '0 1.5rem 1.5rem' }}>
          <Button variant="outlined" onClick={handleCancel}>
            Отмена
          </Button>
          <Button variant="contained" type="html" form="forgot-password-form">
            ладно
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
