import React from 'react';
import classes from './button.module.sass';
import { Button as MuiButton } from '@mui/material';

export function Button({ children, className, variant = 'contained', size = 'medium', style, ...rest }) {
  return (
    <MuiButton className={`${classes.button} ${className}`} variant={variant} size={size} {...rest}>
      {children}
    </MuiButton>
  );
}

export default Button;
