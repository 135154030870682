export const BuyIcon = ({ color = 'white' }) => {
  return (
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.25 6.68753C3.03451 6.68753 2.82785 6.77314 2.67548 6.92551C2.5231 7.07788 2.4375 7.28455 2.4375 7.50003C2.4375 7.71552 2.5231 7.92218 2.67548 8.07456C2.82785 8.22693 3.03451 8.31253 3.25 8.31253H5.05375L7.18575 16.8438C7.36694 17.5669 8.0145 18.0625 8.75956 18.0625H18.8914C19.6251 18.0625 20.2483 17.575 20.4409 16.8682L22.5469 9.12503H20.8455L18.8906 16.4375H8.75875L6.62756 7.90628C6.53953 7.55628 6.33659 7.24595 6.05124 7.02498C5.76589 6.80401 5.41465 6.68518 5.05375 6.68753H3.25ZM17.875 18.0625C16.5384 18.0625 15.4375 19.1635 15.4375 20.5C15.4375 21.8366 16.5384 22.9375 17.875 22.9375C19.2116 22.9375 20.3125 21.8366 20.3125 20.5C20.3125 19.1635 19.2116 18.0625 17.875 18.0625ZM10.5625 18.0625C9.22594 18.0625 8.125 19.1635 8.125 20.5C8.125 21.8366 9.22594 22.9375 10.5625 22.9375C11.8991 22.9375 13 21.8366 13 20.5C13 19.1635 11.8991 18.0625 10.5625 18.0625ZM13 6.68753V10.75H10.5625L13.8125 14L17.0625 10.75H14.625V6.68753H13ZM10.5625 19.6875C11.0208 19.6875 11.375 20.0418 11.375 20.5C11.375 20.9583 11.0208 21.3125 10.5625 21.3125C10.1042 21.3125 9.75 20.9583 9.75 20.5C9.75 20.0418 10.1042 19.6875 10.5625 19.6875ZM17.875 19.6875C18.3333 19.6875 18.6875 20.0418 18.6875 20.5C18.6875 20.9583 18.3333 21.3125 17.875 21.3125C17.4167 21.3125 17.0625 20.9583 17.0625 20.5C17.0625 20.0418 17.4167 19.6875 17.875 19.6875Z"
        fill={color}
      />
    </svg>
  );
};
