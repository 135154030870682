// ** React Import
import { useEffect, useRef, useState } from 'react';

// ** Full Calendar & it's Plugins
import FullCalendar from '@fullcalendar/react';
import listPlugin from '@fullcalendar/list';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import bootstrap5Plugin from '@fullcalendar/bootstrap5';
import interactionPlugin from '@fullcalendar/interaction';
import enLocale from '@fullcalendar/core/locales/en-gb';
import ruLocale from '@fullcalendar/core/locales/ru';
import momentTimezonePlugin from '@fullcalendar/moment-timezone';

// ** Third Party Style Import
import 'bootstrap-icons/font/bootstrap-icons.css';
import { useLanguage } from 'src/hooks';
import { DEFAULT_TIMEZONE } from 'src/utils/time';
import MoreEventsCalendarModal from 'src/components/modal/MoreEventsCalendarModal';

const blankEvent = {
  title: '',
  start: '',
  end: '',
  allDay: false,
  url: '',
  extendedProps: {
    calendar: '',
    guests: [],
    location: '',
    description: '',
  },
};

const Calendar = (props) => {
  // ** Props
  const {
    store,
    dispatch,
    direction,
    updateEvent,
    calendarApi,
    calendarsColor,
    setCalendarApi,
    handleSelectEvent,
    handleLeftSidebarToggle,
    handleAddEventSidebarToggle,
  } = props;

  const { lang } = useLanguage();

  const [allEventsModal, setAllEventsModal] = useState({
    open: false,
    events: [],
    date: '',
  });

  // ** Refs
  const calendarRef = useRef();
  useEffect(() => {
    if (calendarApi === null) {
      // @ts-ignore
      setCalendarApi(calendarRef.current?.getApi());
    }
  }, [calendarApi, setCalendarApi]);
  if (store) {
    // ** calendarOptions(Props)
    const calendarOptions = {
      locales: [enLocale, ruLocale],
      locale: lang.split('_')[0],
      events: store.events.length ? store.events : [],
      timeZone: DEFAULT_TIMEZONE,
      plugins: [interactionPlugin, dayGridPlugin, timeGridPlugin, listPlugin, bootstrap5Plugin, momentTimezonePlugin],
      initialView: 'dayGridMonth',
      headerToolbar: {
        start: 'sidebarToggle, prev, next, title',
        end: 'dayGridMonth,timeGridWeek,timeGridDay,listMonth',
      },
      views: {
        week: {
          titleFormat: { year: 'numeric', month: 'long', day: 'numeric' },
        },
      },

      /*
            Enable dragging and resizing event
            ? Docs: https://fullcalendar.io/docs/editable
          */
      editable: false,

      /*
            Enable resizing event from start
            ? Docs: https://fullcalendar.io/docs/eventResizableFromStart
          */
      eventResizableFromStart: false,

      /*
              Automatically scroll the scroll-containers during event drag-and-drop and date selecting
              ? Docs: https://fullcalendar.io/docs/dragScroll
            */
      dragScroll: false,

      /*
              Max number of events within a given day
              ? Docs: https://fullcalendar.io/docs/dayMaxEvents
            */
      dayMaxEvents: 2,

      /*
              Determines if day names and week names are clickable
              ? Docs: https://fullcalendar.io/docs/navLinks
            */
      navLinks: true,
      eventClassNames({ event: calendarEvent }) {
        // @ts-ignore
        const colorName = calendarsColor[calendarEvent._def.extendedProps.calendar];

        return [
          // Background Color
          `bg-${colorName}`,
        ];
      },
      eventClick({ event: clickedEvent }) {
        dispatch(handleSelectEvent(clickedEvent));
        handleAddEventSidebarToggle();

        // * Only grab required field otherwise it goes in infinity loop
        // ! Always grab all fields rendered by form (even if it get `undefined`) otherwise due to Vue3/Composition API you might get: "object is not extensible"
        // event.value = grabEventDataFromEventApi(clickedEvent)
        // isAddNewEventSidebarActive.value = true
      },
      customButtons: {
        sidebarToggle: {
          icon: 'bi bi-list',
          click() {
            handleLeftSidebarToggle();
          },
        },
      },
      dateClick(info) {
        const ev = { ...blankEvent };
        ev.start = info.date;
        ev.end = info.date;
        ev.allDay = true;

        // @ts-ignore
        // dispatch(handleSelectEvent(ev));
        // handleAddEventSidebarToggle();
      },

      /*
              Handle event drop (Also include dragged event)
              ? Docs: https://fullcalendar.io/docs/eventDrop
              ? We can use `eventDragStop` but it doesn't return updated event so we have to use `eventDrop` which returns updated event
            */
      eventDrop({ event: droppedEvent }) {
        dispatch(updateEvent(droppedEvent));
      },

      /*
              Handle event resize
              ? Docs: https://fullcalendar.io/docs/eventResize
            */
      eventResize({ event: resizedEvent }) {
        dispatch(updateEvent(resizedEvent));
      },
      eventMaxStack: 1,
      moreLinkClick: function (cellInfo) {
        setAllEventsModal({ open: true, events: cellInfo.allSegs ?? [], date: cellInfo.date });
      },
      ref: calendarRef,

      // Get direction from app state (store)
      direction,
    };

    // @ts-ignore
    return (
      <>
        <FullCalendar {...calendarOptions} />
        <MoreEventsCalendarModal
          open={allEventsModal.open}
          events={allEventsModal.events}
          date={allEventsModal.date}
          calendarsColor={calendarsColor}
          onClose={() => {
            setAllEventsModal({ open: false, events: [], date: '' });
          }}
          onClickEvent={(clickedEvent) => {
            dispatch(handleSelectEvent(clickedEvent));
            handleAddEventSidebarToggle();
            setAllEventsModal({ open: false, events: [], date: '' });
          }}
        />
      </>
    );
  } else {
    return null;
  }
};

export default Calendar;
