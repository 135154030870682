import { SCHEDULE_TYPES } from 'src/utils/constant';
import { request } from 'src/utils/request';
import queryString from 'query-string';

export const userScheduleAPIs = {
  getSchedules: (projectUuid, type = SCHEDULE_TYPES.ARRIVAL, limit = 20, offset = 0) => {
    const queries = queryString.stringify({ projectUuid, type, limit, offset });
    return request(`/users/current/schedules?${queries}`, {
      method: 'GET',
    });
  },
  createSchedules: (body) =>
    request(`/users/current/schedules`, {
      method: 'POST',
      body,
    }),
  editSchedules: (scheduleUuid, body) =>
    request(`/users/current/schedules/${scheduleUuid}`, {
      method: 'PATCH',
      body,
    }),
  deleteSchedules: (scheduleUuid) =>
    request(`/users/current/schedules/${scheduleUuid}`, {
      method: 'DELETE',
    }),
};
