import { useEffect, useState } from 'react';
import { useBreakpoint } from './useBreakpoint';

function useProjectHeight({ ref }) {
  const [height, setHeight] = useState(280);
  const { windowSize } = useBreakpoint();

  const handleGetHeight = () => {
    const clientWidth = ref.current.clientWidth;
    setHeight((clientWidth * 5) / 12 + 8);
  };

  useEffect(() => {
    if (windowSize) {
      handleGetHeight();
      window.addEventListener('resize', handleGetHeight);
    }
    return () => {
      window.removeEventListener('resize', handleGetHeight);
    };
  }, [windowSize]);

  return { height };
}

export default useProjectHeight;
