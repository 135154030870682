import moment from 'moment-timezone';
import { DEFAULT_UTC_OFFSET } from './constant';

export const DEFAULT_TIMEZONE = 'Europe/Moscow';

export function timeZone(date = new Date()) {
  const offset = date.getTimezoneOffset();
  const absOffset = Math.abs(offset);
  const hours = Math.floor(absOffset / 60);
  const minutes = absOffset % 60;
  const minutesOut = minutes > 0 ? ':' + ('0' + minutes).slice(-2) : '';
  return (offset < 0 ? '+' : '-') + hours + minutesOut;
}

export const timeZoneConverter = (dateTime, originalTimeZone, targetTimeZone) => {
  const dateTimeStr =
    originalTimeZone > targetTimeZone
      ? moment(dateTime).subtract(originalTimeZone - targetTimeZone, 'h')
      : moment(dateTime).add(targetTimeZone - originalTimeZone, 'h');
  return moment(dateTimeStr);
};

export const convertTimeToDefaultTimezone = (dateTime) => {
  return timeZoneConverter(moment(dateTime), DEFAULT_UTC_OFFSET, timeZone(moment(dateTime).toDate()));
};

export const setDefaultTimezone = (timezone = DEFAULT_TIMEZONE) => {
  timezone && moment.tz.setDefault(timezone);
};

export const getDateMoment = (dateTime) => {
  return moment.tz(dateTime, DEFAULT_TIMEZONE);
};

export const displayTimeByFormat = (dateTime, format) => {
  return getDateMoment(dateTime).format(format);
};

export const getDateISOString = (dateTime) => {
  return getDateMoment(dateTime).toISOString();
};
