import { useEffect, useState } from 'react';

const LOCAL_STORAGE_KEY = 'expireTime';
const LIMIT_INACTIVE = 10000; // 10s

function useInactivityUser({ shouldCheck }) {
  const [shouldAlert, setShouldAlert] = useState(false);

  const alertUser = () => {
    const expireTime = localStorage.getItem(LOCAL_STORAGE_KEY);
    if (expireTime < Date.now()) {
      setShouldAlert(true);
    }
  };

  const updateExpireTime = () => {
    localStorage.setItem(LOCAL_STORAGE_KEY, Date.now() + LIMIT_INACTIVE);
  };

  useEffect(() => {
    const alterInterval = setInterval(() => {
      if (shouldCheck) alertUser();
    }, LIMIT_INACTIVE);

    if (shouldCheck) {
      updateExpireTime();

      window.addEventListener('click', updateExpireTime);
      window.addEventListener('keypress', updateExpireTime);
      window.addEventListener('scroll', updateExpireTime);
      window.addEventListener('mousemove', updateExpireTime);
    }

    return () => {
      clearInterval(alterInterval);
      window.removeEventListener('click', updateExpireTime);
      window.removeEventListener('keypress', updateExpireTime);
      window.removeEventListener('scroll', updateExpireTime);
      window.removeEventListener('mousemove', updateExpireTime);
    };
  }, [shouldCheck]);

  return { shouldAlert: shouldAlert && shouldCheck, setShouldAlert };
}

export default useInactivityUser;
