import React, { useEffect, useRef } from 'react';
import { EVENTS } from 'src/utils/constant';
import eventBus from 'src/utils/eventBus';
import NotificationSound from './the-notification-email.mp3';

function Audio() {
  const audioPlayer = useRef(null);

  function playAudio() {
    audioPlayer.current.play();
  }

  useEffect(() => {
    eventBus.on(EVENTS.PLAY_NOTIFICATION_SOUND, playAudio);
    return () => {
      eventBus.off(EVENTS.PLAY_NOTIFICATION_SOUND, playAudio);
    };
  }, [audioPlayer]);

  return <audio ref={audioPlayer} src={NotificationSound} />;
}

export default Audio;
