const CircularProgress = () => {
  return {
    MuiCircularProgress: {
      styleOverrides: {
        root: {
          display: 'flex',
          margin: 'auto',
        },
      },
    },
  };
};

export default CircularProgress;
