// ** React Imports
import { useRef, useEffect } from 'react';

// ** MUI Imports
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

// ** Icon Imports
import Icon from 'src/@core/components/icon';

// ** Third Party Components
import PerfectScrollbarComponent from 'react-perfect-scrollbar';

// ** Custom Components Imports
import CustomAvatar from 'src/@core/components/mui/avatar';

// ** Utils Imports
import { getInitials } from 'src/@core/utils/get-initials';
import Loader from 'src/components/loader';
import { dispatch } from 'src/store';
import { fetchMeetingsInChat, fetchMoreChatMessages, setErrorMessages } from 'src/store/apps/chat';
import { useSelector } from 'react-redux';
import { Badge, Button, Collapse, IconButton, Stack } from '@mui/material';
import MeetingTime from 'src/components/meeting-time';
import { MESSAGES, TEXT } from 'src/utils/constant';
import { userAPIs } from 'src/services';
import MeetingActions from 'src/components/meeting-actions';
import { createErrorNotification, createNotification } from 'src/utils/notifications';
import { useParams } from 'react-router-dom';
import MeetingStatus from 'src/components/status/MeetingStatus';
import { InView } from 'react-intersection-observer';
import { Error, ErrorOutline } from '@mui/icons-material';
import ChatMeetings from './ChatMeetings';

const PerfectScrollbar = styled(PerfectScrollbarComponent)(({ theme }) => ({
  padding: theme.spacing(5),
}));

const ChatLog = (props) => {
  // ** Props
  const { data, hidden, showMeetings, onSayHi, sendMsg } = props;
  const selectedChat = useSelector((state) => state.chat.selectedChat);
  const meetingsChat = useSelector((state) => state.chat.meetingsChat);
  const isLoadingMeetings = useSelector((state) => state.chat.isLoadingMeetings);
  const errorMessages = useSelector((state) => state.chat.errorMessages);
  const lang = useSelector((state) => state.app.lang);
  const currentUser = useSelector((state) => state.auth.user);
  const { projectUuid } = useParams();
  const { showTableNumber } = useSelector((state) => state.app.projectSettings || {});

  // ** Ref
  const chatArea = useRef(null);

  const handleResendMessage = (message) => {
    dispatch(setErrorMessages(errorMessages.filter((uuid) => uuid !== message.uuid)));
    dispatch(sendMsg({ ...selectedChat, message: message.msg, messageUuid: message.uuid, isResend: true }));
  };

  // ** Scroll to chat bottom
  const scrollToBottom = () => {
    if (chatArea.current) {
      if (hidden) {
        // @ts-ignore
        chatArea.current.scrollTop = Number.MAX_SAFE_INTEGER;
      } else {
        // @ts-ignore
        chatArea.current._container.scrollTop = Number.MAX_SAFE_INTEGER;
      }
    }
  };

  // ** Formats chat data based on sender
  const formattedChatData = () => {
    let chatLog = [];
    if (data.chat) {
      chatLog = data.chat.chat;
    }
    const formattedChatLog = [];
    let chatMessageSenderId = chatLog[0] ? chatLog[0].senderId : 11;

    let msgGroup = {
      senderId: chatMessageSenderId,
      messages: [],
    };
    chatLog.forEach((msg, index) => {
      if (chatMessageSenderId === msg.senderId) {
        msgGroup.messages.push({
          uuid: msg.uuid,
          time: msg.time,
          msg: msg.message,
          feedback: msg.feedback,
        });
      } else {
        chatMessageSenderId = msg.senderId;
        formattedChatLog.push(msgGroup);
        msgGroup = {
          senderId: msg.senderId,
          messages: [
            {
              uuid: msg.uuid,
              time: msg.time,
              msg: msg.message,
              feedback: msg.feedback,
            },
          ],
        };
      }
      if (index === chatLog.length - 1) formattedChatLog.push(msgGroup);
    });

    return formattedChatLog;
  };

  const renderMsgFeedback = (isSender, feedback) => {
    if (isSender) {
      if (feedback.isSent && !feedback.isDelivered) {
        return (
          <Box component="span" sx={{ display: 'inline-flex', '& svg': { mr: 2, color: 'text.secondary' } }}>
            <Icon icon="mdi:check" fontSize="1rem" />
          </Box>
        );
      } else if (feedback.isSent && feedback.isDelivered) {
        return (
          <Box
            component="span"
            sx={{
              display: 'inline-flex',
              '& svg': { mr: 2, color: feedback.isSeen ? 'success.main' : 'text.secondary' },
            }}
          >
            <Icon icon="mdi:check-all" fontSize="1rem" />
          </Box>
        );
      } else {
        return null;
      }
    }
  };
  useEffect(() => {
    if (data && data.chat && data.chat.chat.length) {
      scrollToBottom();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  // ** Renders user chat
  const renderChats = () => {
    return formattedChatData().map((item, index) => {
      const isSender = item.senderId === data.userContact.id;

      return (
        <Box
          key={index}
          sx={{
            display: 'flex',
            flexDirection: !isSender ? 'row' : 'row-reverse',
            mb: index !== formattedChatData().length - 1 ? 4 : undefined,
          }}
        >
          <div>
            <CustomAvatar
              skin="light"
              color={data.contact.avatarColor ? data.contact.avatarColor : undefined}
              sx={{
                width: '2rem',
                height: '2rem',
                fontSize: '0.875rem',
                ml: isSender ? 3.5 : undefined,
                mr: !isSender ? 3.5 : undefined,
              }}
              {...(data.contact.avatar && !isSender
                ? {
                    src: data.contact.avatar,
                    alt: data.contact.fullName,
                  }
                : {})}
              {...(isSender
                ? {
                    src: data.userContact.avatar,
                    alt: data.userContact.fullName,
                  }
                : {})}
            >
              {data.contact.avatarColor ? getInitials(data.contact.fullName) : null}
            </CustomAvatar>
          </div>

          <Box className="chat-body" sx={{ maxWidth: ['calc(100% - 5.75rem)', '75%', '65%'] }}>
            {item.messages.map((chat, index, { length }) => {
              const time = new Date(chat.time);
              const hasError = errorMessages?.includes(chat.uuid);

              return (
                <Box key={index} sx={{ '&:not(:last-of-type)': { mb: 3.5 } }}>
                  <Stack direction="row" sx={{ width: '100%' }} alignItems="center" gap="4px">
                    <Typography
                      sx={{
                        boxShadow: 1,
                        borderRadius: 1,
                        width: 'fit-content',
                        fontSize: '0.875rem',
                        p: (theme) => theme.spacing(3, 4),
                        ml: isSender ? 'auto' : undefined,
                        borderTopLeftRadius: !isSender ? 0 : undefined,
                        borderTopRightRadius: isSender ? 0 : undefined,
                        color: isSender ? 'common.white' : 'text.primary',
                        backgroundColor: isSender ? 'primary.main' : 'background.paper',
                        overflowWrap: 'anywhere',
                      }}
                    >
                      {chat.msg}
                    </Typography>
                    {hasError ? (
                      <>
                        <Error color="error" />
                        <Button size="small" variant="outlined" onClick={() => handleResendMessage(chat)}>
                          {TEXT[lang].RESEND}
                        </Button>
                      </>
                    ) : (
                      <></>
                    )}
                  </Stack>
                  {index + 1 === length ? (
                    <Box
                      sx={{
                        mt: 1,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: isSender ? 'flex-end' : 'flex-start',
                      }}
                    >
                      {/* {renderMsgFeedback(isSender, chat.feedback)} */}
                      <Typography variant="caption">
                        {time
                          ? new Date(time).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
                          : null}
                      </Typography>
                    </Box>
                  ) : null}
                </Box>
              );
            })}
          </Box>
        </Box>
      );
    });
  };

  const ScrollWrapper = ({ children }) => {
    if (hidden) {
      return (
        <Box ref={chatArea} sx={{ p: 5, height: '100%', overflowY: 'auto', overflowX: 'hidden' }}>
          {children}
        </Box>
      );
    } else {
      return (
        <PerfectScrollbar ref={chatArea} options={{ wheelPropagation: false }}>
          {children}
        </PerfectScrollbar>
      );
    }
  };

  function onFetchMoreChatMessages(isVisible) {
    if (isVisible) {
      dispatch(fetchMoreChatMessages());
    }
  }

  const updateMeetingStatus = async (meetingUuid, status) => {
    try {
      await userAPIs.updateMeetingStatus(projectUuid, meetingUuid, { status });
      createNotification(MESSAGES[lang].ADDED_TO_CALENDAR);
      dispatch(fetchMeetingsInChat({ chatUuid: selectedChat?.chat?.uuid, projectUuid }));
    } catch (e) {
      console.log(e);
      createErrorNotification(MESSAGES[lang].ERROR);
    }
  };

  return (
    <Box sx={{ height: 'calc(100% - 8.8125rem)', overflow: 'auto', position: 'relative' }}>
      {showMeetings && (
        <Stack
          sx={{
            position: 'absolute',
            background: '#fff',
            inset: '1rem 1.25rem auto',
            padding: '8px',
            boxShadow: (theme) => theme.shadows[10],
            borderRadius: '8px',
            zIndex: 1,
            maxHeight: '250px',
            overflow: 'auto',
          }}
          gap="4px"
        >
          {isLoadingMeetings ? (
            <Loader size="24px" />
          ) : (
            <ChatMeetings meetings={meetingsChat} onUpdateMeetingStatus={updateMeetingStatus} />
          )}
        </Stack>
      )}

      <ScrollWrapper>
        {renderChats()}
        {formattedChatData().length === 0 && (
          <Stack alignItems="center" justifyContent="center" sx={{ position: 'absolute', inset: 0 }}>
            <Box
              sx={{
                ':hover': {
                  filter: 'drop-shadow(2px 4px 24px grey)',
                },
                fontSize: '80px',
                cursor: 'pointer',
              }}
              onClick={onSayHi}
            >
              🤚
            </Box>
          </Stack>
        )}
        <Box>
          {selectedChat?.chat?.canLoadMore && (
            <InView onChange={onFetchMoreChatMessages}>
              <Loader size={18} />
            </InView>
          )}
        </Box>
      </ScrollWrapper>
    </Box>
  );
};

export default ChatLog;
