import { Warning } from '@mui/icons-material';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { EVENTS, LOCALIZATION, TEXT } from '../../utils/constant';
import eventBus from '../../utils/eventBus';

export const ConfirmModal = () => {
  const lang = useSelector((state) => state.app.lang) || LOCALIZATION.ru_RU;
  const [state, setState] = useState({
    show: false,
    title: TEXT[lang].CONFIRM,
    content: TEXT[lang].ARE_YOU_SURE,
    okText: TEXT[lang].OK,
    cancelText: TEXT[lang].CANCEL,
    onOk: () => {},
  });

  const handleCancel = () => setState({ show: false });
  const handleShow = (data) => {
    setState({
      ...state,
      title: TEXT[lang].CONFIRM,
      content: TEXT[lang].ARE_YOU_SURE,
      okText: TEXT[lang].OK,
      cancelText: TEXT[lang].CANCEL,
      ...data,
      show: true,
    });
  };

  const handleOk = async () => {
    try {
      await state.onOk();
    } catch (e) {
      console.log(e);
    } finally {
      handleCancel();
    }
  };

  useEffect(() => {
    eventBus.on(EVENTS.OPEN_CONFIRM_MODAL, handleShow);
    return () => {
      eventBus.off(EVENTS.OPEN_CONFIRM_MODAL, handleShow);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang]);

  return (
    <>
      <Dialog
        sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
        maxWidth="xs"
        color="warning"
        open={state.show}
        onClose={handleCancel}
      >
        <DialogTitle color="war">
          <Stack direction="row" alignItems="center" gap={2}>
            {state.showWarning && <Warning color="warning" />}
            <span>{state.title}</span>
          </Stack>
        </DialogTitle>
        <DialogContent sx={{ fontWeight: state.contentBold ? 700 : 200 }}>{state.content}</DialogContent>
        <DialogActions sx={{ padding: '0 1.5rem 1.5rem' }}>
          <Button variant="outlined" onClick={handleCancel}>
            {state.cancelText}
          </Button>
          <Button variant="contained" onClick={handleOk}>
            {state.okText}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
