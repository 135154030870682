import { deepmerge } from '@mui/utils';

import breakpoints from './breakpoints';
import overrides from './overrides';
import palette from './palette';
import shadows from './shadows';
import spacing from './spacing';
import typography from './typography';

const themeOptions = () => {
  const mergedThemeConfig = deepmerge({
    breakpoints: breakpoints(),
    components: overrides(),
    palette: palette(),
    ...spacing,
    shadows: shadows(),
    typography,
  });
  return mergedThemeConfig;
};

export default themeOptions;
