import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { userAPIs } from 'src/services';

function useAllowedNetworking(args) {
  const { dependencies = [] } = args || {};
  const { projectUuid } = useParams();

  const fetchAllowedNetworking = async ({ queryKey }) => {
    const [_, projectUuid] = queryKey;
    const response = await userAPIs.getIsAllowedNetworking(projectUuid);
    return response.message;
  };

  const { data: isAllowedNetworking, ...rest } = useQuery(
    ['fetchAllowedNetworking', projectUuid, ...dependencies],
    fetchAllowedNetworking,
    {
      enabled: !!projectUuid,
    }
  );
  return { isAllowedNetworking, ...rest };
}

export default useAllowedNetworking;
