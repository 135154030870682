import { ENDPOINT } from '../utils/constant';
import { request } from '../utils/request';

export const authAPIs = {
  login: (payload) =>
    request(`${ENDPOINT.AUTH.BASE}${ENDPOINT.AUTH.LOGIN}`, {
      method: 'POST',
      body: payload,
    }),
  register: (payload) =>
    request(`${ENDPOINT.AUTH.BASE}${ENDPOINT.AUTH.REGISTRATION}`, {
      method: 'POST',
      body: payload,
    }),
  forgotPassword: (payload) =>
    request(`${ENDPOINT.AUTH.BASE}${ENDPOINT.AUTH.FORGOT_PASSWORD}`, {
      method: 'POST',
      body: payload,
    }),
  checkEmail: (payload) =>
    request(`${ENDPOINT.AUTH.BASE}${ENDPOINT.AUTH.CHECK_EMAIL}`, {
      method: 'POST',
      body: payload,
    }),
  loginExternal: (provider = 'yandex') =>
    request(`/auth/${provider}`, {
      method: 'GET',
      credentials: 'include',
    }),
};
