export const AUTH__LOGIN_SUCCESS = 'AUTH__LOGIN_SUCCESS';
export const AUTH__LOGOUT = 'AUTH__LOGOUT';
export const AUTH__ERROR = 'AUTH__ERROR';
export const AUTH__SET_PASSPORT = 'AUTH__SET_PASSPORT';
export const AUTH__SET_LOADING_AVATAR = 'AUTH__SET_LOADING_AVATAR';
export const AUTH__SET_AVATAR = 'AUTH__SET_AVATAR';
export const AUTH__SET_PROJECTS = 'AUTH__SET_PROJECTS';
export const AUTH__UPDATE_USER = 'AUTH__UPDATE_USER';
export const AUTH__SET_LOADING = `AUTH__SET_LOADING`;
export const AUTH__SET_COMPANY = `AUTH__SET_COMPANY`;
export const AUTH__SET_MY_ORDERS = `AUTH__SET_MY_ORDERS`;
export const AUTH__SET_ARCHIVED_ORDERS = `AUTH__SET_ARCHIVED_ORDERS`;
export const AUTH__SET_SCANNING_COUNT = `AUTH__SET_SCANNING_COUNT`;
export const AUTH__SET_IS_NEW_ACCOUNT = `AUTH__SET_IS_NEW_ACCOUNT`;
