import { FileDownload } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { useEffect, useState } from 'react';
import ImgsViewer from 'react-images-viewer';
import { EVENTS } from '../../utils/constant';
import eventBus from '../../utils/eventBus';

export const ViewImageModal = () => {
  const [state, setState] = useState({
    show: false,
    fileName: 'passport.png',
    images: [],
  });

  const handleCancel = () => setState({ show: false });
  const handleShow = (data) => {
    setState({ ...state, ...data, show: true });
  };

  function downloadURI(uri, name) {
    if (!uri) return;
    let link = document.createElement('a');
    link.download = name;
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  const handleDownload = () => {
    downloadURI(state.images[0]?.src, state.fileName);
  };

  useEffect(() => {
    eventBus.on(EVENTS.OPEN_VIEW_IMAGE_MODAL, handleShow);
    return () => {
      eventBus.off(EVENTS.OPEN_VIEW_IMAGE_MODAL, handleShow);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ImgsViewer
        isOpen={state.show}
        imgs={state.images ?? []}
        current={state.images?.[0]}
        onClose={handleCancel}
        backdropCloseable={true}
        customControls={[
          <IconButton key={1} onClick={handleDownload}>
            <FileDownload style={{ color: 'white' }} />
          </IconButton>,
        ]}
      />
    </>
  );
};
