import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { Button, Stack } from '@mui/material';
import { EVENTS, LOCALIZATION, MEETING_STATUS } from 'src/utils/constant';
import eventBus from 'src/utils/eventBus';

function MeetingActions({ meeting, onUpdateMeetingStatus }) {
  const handleDeclineMeeting = (e, meeting) => {
    e.preventDefault();
    e.stopPropagation();
    eventBus.emit(EVENTS.OPEN_CONFIRM_MODAL, {
      onOk: () => {
        onUpdateMeetingStatus(meeting.uuid, MEETING_STATUS.declined[LOCALIZATION.en_US]);
      },
    });
  };

  const handleApproveMeeting = (e, meeting) => {
    e.preventDefault();
    e.stopPropagation();
    onUpdateMeetingStatus(meeting.uuid, MEETING_STATUS.approved[LOCALIZATION.en_US]);
  };

  if (!meeting) return null;
  return (
    <Stack direction="row" gap="8px" alignItems="center" justifyContent={{ xs: 'center', md: 'flex-start' }}>
      {meeting.status !== MEETING_STATUS.declined[LOCALIZATION.en_US] && (
        <Button
          variant="outlined"
          sx={{ height: '24px', width: '24px', minWidth: '24px', borderRadius: '4px', padding: '0' }}
          onClick={(e) => handleDeclineMeeting(e, meeting)}
        >
          <CloseIcon />
        </Button>
      )}
      {meeting.status !== MEETING_STATUS.approved[LOCALIZATION.en_US] && (
        <Button
          variant="contained"
          sx={{ height: '24px', width: '24px', minWidth: '24px', borderRadius: '4px', padding: '0' }}
          onClick={(e) => handleApproveMeeting(e, meeting)}
        >
          <CheckIcon />
        </Button>
      )}
    </Stack>
  );
}

export default MeetingActions;
