import { useEffect, useState } from 'react';
import { useBreakpoint } from './useBreakpoint';

function useProjectImage({ project }) {
  const [mainPhoto, setMainPhoto] = useState(project?.photos?.web_big?.[0]);
  const { windowSize } = useBreakpoint();

  const handleChooseMainPhoto = () => {
    const clientWidth = windowSize.width;
    const webBigImage = project.photos?.web_big?.[0];
    const webSmallImage = project.photos?.web_image?.[0];
    const mobileBigImage = project.photos?.mobile_big?.[0];
    const mobileSmallImage = project.photos?.mobile_small?.[0];
    if (clientWidth > 1200) {
      setMainPhoto(webBigImage);
    } else if (clientWidth > 900) {
      setMainPhoto(webSmallImage);
    } else if (clientWidth > 600) {
      setMainPhoto(mobileBigImage);
    } else {
      setMainPhoto(mobileSmallImage);
    }
  };

  useEffect(() => {
    if (windowSize) {
      handleChooseMainPhoto();
      window.addEventListener('resize', handleChooseMainPhoto);
    }
    return () => {
      window.removeEventListener('resize', handleChooseMainPhoto);
    };
  }, [windowSize, project]);

  return { mainPhoto: mainPhoto };
}

export default useProjectImage;
