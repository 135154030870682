import queryString from 'query-string';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { routes } from 'src/utils/routes';
import classes from './payment-fail.module.sass';

const PaymentFail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const query = queryString.parse(location.search);

  useEffect(() => {
    if (query) {
      setTimeout(() => {
        navigate(query.projectUuid ? routes.myOrder.path.replace(':projectUuid', query.projectUuid) : '/');
      }, 500);
    }
  }, [query]);

  return <p className={classes.payment}>Оплата неуспешно</p>;
};

export default PaymentFail;
