import { ENDPOINT } from '../utils/constant';
import { downloadFile, request } from '../utils/request';

export const generalAPIs = {
  getCountries: (query = '') =>
    request(ENDPOINT.COUNTRIES.BASE + query, {
      method: 'GET',
    }),
  getRoles: (query = '') =>
    request(ENDPOINT.ROLES.BASE + query, {
      method: 'GET',
    }),
  getPublicVizitWithAuth: (code) => request(`/users/current/public/vizit?code=${code}`, { method: 'GET' }),
  getPublicVizit: (code) => request(`/public/vizit?code=${code}`, { method: 'GET' }),
  getVCard: (code) => request(`/public/v-card?code=${code}`, { method: 'GET' }, true),
  downloadVCard: (code) => downloadFile(`/public/v-card?code=${code}`, 'v-card.vcf'),
  getAutoFillFields: () => request(`/auto-fill-templates`, { method: 'GET' }),
  checkCaptcha: (token) => request(`/captcha?token=${token}`),
};
