import { yupResolver } from '@hookform/resolvers/yup';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  TextField,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { userAPIs } from '../../services';
import { getTransMessages, getTransText } from '../../utils/common';
import { EVENTS, LOCALIZATION } from '../../utils/constant';
import eventBus from '../../utils/eventBus';
import { changePasswordValidationSchema } from '../../utils/formValidator';
import { createErrorNotification, createNotification } from '../../utils/notifications';

export const ChangePasswordForm = () => {
  const transText = getTransText();
  const transMessages = getTransMessages();
  const [state, setState] = useState({
    show: false,
  });
  const [showPassword, setShowPassword] = useState({
    newPassword: false,
    confirmPassword: false,
  });
  const lang = useSelector((state) => state.app.lang) || LOCALIZATION.ru_RU;

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: { oldPassword: '', newPassword: '', confirmPassword: '' },
    resolver: yupResolver(changePasswordValidationSchema(lang)),
  });

  const handleCancel = () => {
    reset();
    setState({ show: false });
  };

  const handleShow = (data) => {
    setState({ ...state, ...data, show: true });
  };

  const handleOk = async ({ oldPassword, newPassword }) => {
    try {
      await userAPIs.changePassword({ oldPassword, newPassword });
      createNotification(transMessages.PASSWORD_HAS_BEEN_CHANGED);
      handleCancel();
    } catch (e) {
      console.log(e);
      createErrorNotification(transMessages.YOUR_PASSWORD_NOT_CORRECT);
    }
  };

  useEffect(() => {
    eventBus.on(EVENTS.OPEN_CHANGE_PASSWORD_FORM, handleShow);
    return () => {
      eventBus.off(EVENTS.OPEN_CHANGE_PASSWORD_FORM, handleShow);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const labelStyle = { fontSize: 17, fontFamily: 'Lato' };
  const inputStyle = { fontSize: 20, fontFamily: 'Lato' };
  return (
    <>
      <Dialog
        sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
        maxWidth="xs"
        open={state.show}
        onClose={handleCancel}
      >
        <DialogTitle>{transText.CHANGE_PASSWORD}</DialogTitle>
        <DialogContent sx={{ padding: '20px 24px !important' }}>
          <form id="forgot-password-form" onSubmit={handleSubmit(handleOk)}>
            <TextField
              label={transText.YOUR_PASSWORD}
              type="password"
              InputLabelProps={{
                shrink: true,
                style: labelStyle,
              }}
              InputProps={{
                style: inputStyle,
              }}
              sx={{ width: '100%' }}
              autoFocus
              variant="standard"
              {...register('oldPassword')}
              error={errors.oldPassword ? true : false}
              helperText={errors.oldPassword?.message}
            />
            <TextField
              label={transText.NEW_PASSWORD}
              type={showPassword.newPassword ? 'text' : 'password'}
              margin="normal"
              InputLabelProps={{
                shrink: true,
                style: labelStyle,
              }}
              InputProps={{
                style: inputStyle,
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowPassword({ ...showPassword, newPassword: !showPassword.newPassword })}
                    >
                      {showPassword.newPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              sx={{ width: '100%' }}
              variant="standard"
              {...register('newPassword')}
              error={errors.newPassword ? true : false}
              helperText={errors.newPassword?.message}
            />
            <TextField
              label={transText.NEW_PASSWORD_REPEAT}
              type={showPassword.confirmPassword ? 'text' : 'password'}
              margin="normal"
              InputLabelProps={{
                shrink: true,
                style: labelStyle,
              }}
              InputProps={{
                style: inputStyle,
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() =>
                        setShowPassword({ ...showPassword, confirmPassword: !showPassword.confirmPassword })
                      }
                    >
                      {showPassword.confirmPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              sx={{ width: '100%' }}
              variant="standard"
              {...register('confirmPassword')}
              error={errors.confirmPassword ? true : false}
              helperText={errors.confirmPassword?.message}
            />
          </form>
        </DialogContent>
        <DialogActions sx={{ padding: '0 1.5rem 1.5rem' }}>
          <Button variant="outlined" onClick={handleCancel}>
            {transText.CANCEL}
          </Button>
          <Button variant="contained" type="html" form="forgot-password-form">
            {transText.OK}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
