import { useQuery } from 'react-query';
import { projectsAPIs } from 'src/services';

function usePrograms({ projectUuid }) {
  const fetchPrograms = async () => {
    const resPrograms = await projectsAPIs.getProgramsOfProject(projectUuid);
    return resPrograms.message || [];
  };

  const queryResult = useQuery(['fetchPrograms', projectUuid], fetchPrograms, { enabled: !!projectUuid });
  return queryResult;
}

export default usePrograms;
